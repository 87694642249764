import styled from 'styled-components';  

export const FetauredImageStyle = styled.div`
    .box-image {
        position: relative;
        height: 0;
        overflow: hidden;
        padding-bottom: 60%;
    }
 
    .box-image img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;
export const CursoStyle = styled.div`
    width:100%;
    .information-box{
        border-bottom: 1.2px solid #e5e5e5;
        border-top: 1.2px solid #e5e5e5;
        padding: 30px 0px;
    }
    .addCurso{
        width: 100%;
        color: #fff;
        background-color: #282529;
        padding: 10px 6px;
        -webkit-transition: ease all 200ms;
        transition: ease all 200ms;
        text-align: center;
        max-width: 225px;
        &:hover {
            text-decoration: none;
            background-color: #fff;
            color: #282529;
            border: 1px solid #282529;
        }
    }
    .dadosCurso{
        h2{
            margin-bottom: 15px;
        }
    }
    & .info-curso{
        border:none!important;
        margin:0 auto;
    }
`;