import styled from 'styled-components';


export const FilterStyle = styled.div`
   & select{
    border: none;
    border-bottom: 1px solid #c7c7c7;
    color: #000000;
    font-size: 13px;
    font-family: "HelveticaLTStd";
    outline: none;
    padding: 7px 5px;
    font-weight: 300;
   }
   & input{
    border: none;
    border-bottom: 1px solid #c7c7c7;
    color: #000000;
    font-size: 13px;
    font-family: "HelveticaLTStd";
    outline: none;
    padding: 7px 5px;
    font-weight: 300;
   }
   & .search-filter{
    position: relative;
   }
   & i{
    position: absolute;
    right: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
   }
`;
