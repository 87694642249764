import React from 'react';
import PropTypes from 'prop-types';
import Components from './ComponentList';

const ComponentFactory = ({ componentName, content, block }) => {
  const ComponentToRender = Components[componentName];
  if (ComponentToRender) {
    return <ComponentToRender block={block} content={content} />
  }
  return null;
};

ComponentFactory.propTypes = {
  componentName: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired
};

export default ComponentFactory
