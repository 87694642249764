import React, { useState, useEffect } from 'react';
import Base from '../../../../components/Theme/base';
import CursoPagePresencial from '../../../../components/Cursos/Presencial';
import SubTitle from '../../../../components/Theme/Title/SubTitle';
import CourseItem from '../../../../components/Home/Cursos/Presencial/CourseItem';
import img from '../../../../assets/images/banner.png';
import { Container, Col, Row } from 'reactstrap';
import api from '../../../../services/api';
import userPlaceholder from '../../../../assets/images/athie_placeholder.png';


const CursoPresencial = () => {

  const [courses, setCourses] = useState([]);
  const [coursesActual, setCoursesActual] = useState([]);
  useEffect(() => {

    api.get(window.location.pathname.replace('/presencial', '')).then((res) => {

      var course = {
        _id: `/lms/classroom_courses${res.data.id}`,
        id: res.data.id,
        image: {
          urlPublica: res.data.image !== null ? res.data.image.urlPublica : userPlaceholder
        },
        data: res.data.published_at,
        titulo: res.data.title,
        cidade: res.data.city,
        local: res.data.local,
        endereco: res.data.address,
        description: res.data.summary,
        information: res.data.summary,
        url: `/presencial/lms/classroom_courses/${res.data.id}`
      };
      setCoursesActual(course);
    })
      .catch((err) => {
        console.error('Erro ao Buscar Curso Atual', err)
      })


    const data = [];
    api.get('/lms/classroom_courses?order[published_at]=desc')
      .then((res) => {
        res.data.forEach(element => {
          if (`/lms/courses/${element.id}` !== window.location.pathname.replace('/presencial', '')) {
            if (element.active) {
              data.push({
                _id: `/presencial/lms/classroom_courses/${element.id}`,
                id: element.id,
                image: {
                  urlPublica: element.image !== null ? element.image.urlPublica : userPlaceholder
                },
                data: element.created_at,
                titulo: element.title,
                cidade: element.city,
                local: element.local,
                endereco: element.address,
                description: element.summary,
                information: element.summary,
                url: `/presencial/lms/classroom_courses/${element.id}`
              })
            }
          }
        })
        setCourses(data);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos: ', err);
      })
    return () => {
      setCourses([]);
    };
  }, []);


  return (
    <Base>
      <CursoPagePresencial course={coursesActual} />
      <Container className="p-0">
        {courses.length > 1 &&
          <SubTitle value="Sugestão de Cursos" />}
        <Row>
          {courses.length > 0 &&
            courses.slice(0, 4).map((course, i) =>
              <Col key={`courseItem${i}`} xs="12" md="6" lg="3" className="mb-4  py-2">  <CourseItem key={`courseItem${i}`} course={course} /> </Col>
            )}
        </Row>
        {/* <Row>
          <button className="col-3 LoadMoreButtom">Carregar Mais</button>
        </Row> */}
      </Container>
    </Base>
  )

};
export default CursoPresencial;
