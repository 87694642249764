import React, {useState, useEffect, useContext} from 'react';
import {
  StarsStyle,
  Professor,
  ImgProfessor,
  UserImg,
  NomeProfessor,
  ActionsStyle,
  Views,
  Icon,
  ModalExamSytled,
  AvalieStyle,
  FavortitosStyle,
  ProgressCurso,
  ProvaStyle,
} from './styles';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import InternalTitle from '../../../components/Theme/Title/InternalTitle';
import ProgressBar from '../../../components/Theme/ProgressBar';
import {Container, Col, Row} from 'reactstrap';
import Aula from './Aula';
import api from '../../../services/api';
import format from '../../../utils/format';
import Exame from '../../Exames';
import {Spinner} from 'reactstrap';
import getCurrentUser from '../../../utils/getCurrentUser';
import ContextTrail from './contextTrail';

const CursoPageOnline = ({course, ...props}) => {
  const setReload = useContext(ContextTrail);
  const closeModal = true;
  const [progress, setProgress] = useState([]);
  const [evaluationUserRating, setEvaluationUserRating] = useState(5);
  const [evaluationUserFavorite, setEvaluationUserFavorite] = useState(0);
  const [openExamModal, setOpenExamModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [evaluation, setEvaluation] = useState(course ? course.nota : 5);

  function openExam(close = false) {
    setOpenExamModal(!openExamModal);
    if (close && setReload) {
      setReload((prev) => !prev);
    }
  }

  useEffect(() => {
    if (course) {
      api
        .get(
          `/general/currentUserEvaluations?content_iri=/lms/courses/${course.id}`,
        )
        .then((response) => {
          const res = response.data || [];
          const ratings =
            res.filter((el) => el.type === 'RATING').length > 0
              ? res.filter((el) => el.type === 'RATING')[0].average
              : 5;
          setEvaluation(ratings);
        })
        .catch((err) => {
          console.error('Erro na nota', err);
        });
      api
        .get(
          `/general/evaluations?content_iri=${course._id}&user_iri=${atob(
            localStorage.getItem(`user-endpoint`),
          )}`,
        )
        .then((responseUser) => {
          const resUser = responseUser.data || [];
          const ratingsUser =
            resUser.filter((el) => el.type === 'RATING').length > 0
              ? resUser.filter((el) => el.type === 'RATING')[0].value
              : 0;
          const favoritesUser =
            resUser.filter((el) => el.type === 'FAVORITE').length > 0
              ? resUser.filter((el) => el.type === 'FAVORITE')[0].value
              : 'false';
          setEvaluationUserRating(ratingsUser);
          setEvaluationUserFavorite(favoritesUser);
          setLoading(true);
        });
    }
    return () => {
      setEvaluationUserRating([]);
      setEvaluationUserFavorite([]);
      setEvaluation([]);
    };
  }, [course]);

  function updateRating(val) {
    api
      .post('/general/evaluations', {
        user_iri: atob(localStorage.getItem(`user-endpoint`)),
        content_iri: course._id,
        value: val,
        type: 'RATING',
      })
      .then((response) => {
        setEvaluationUserRating(val);
      })
      .catch((err) => {
        console.error('ERRO AO ATUALIZAR RATING', err);
      });
  }
  function updateFavorite() {
    api
      .post('/general/evaluations', {
        user_iri: atob(localStorage.getItem(`user-endpoint`)),
        content_iri: course._id,
        value: 1,
        type: 'FAVORITE',
      })
      .then((response) => {
        setEvaluationUserFavorite(evaluationUserFavorite === '1' ? 0 : '1');
      })
      .catch((err) => {
        console.error('Erro ao Setar Favorite', err);
      });
  }

  const stars = [];
  for (var i = 0; i < 5; i++) {
    evaluation > i ? stars.push('fas') : stars.push('far');
  }
  const starsUser = [];
  for (var i = 0; i < 5; i++) {
    evaluationUserRating > i ? starsUser.push('fas') : starsUser.push('far');
  }

  return (
    <>
      {!loading ? (
        <div className="mt-30 mb-30 text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <InternalTitle value={course.titulo} />

          <Container className="p-0">
            <Row>
              <Col xs="12" className="d-flex flex-wrap">
                <Professor className="col-12 col-lg-4">
                  <ImgProfessor>
                    <UserImg src={course.photo} />
                  </ImgProfessor>
                  <span className="d-flex mr-5 align-items-center">
                    <NomeProfessor>
                      Por:{course.autor} |{' '}
                      {format.data(course.data, 'numberDay')}{' '}
                      {typeof course.data !== 'undefined'
                        ? format
                            .data(course.data, 'stringMonth')
                            .slice(0, 3)
                            .toUpperCase()
                        : ''}{' '}
                      {format.data(course.data, 'numberYear')}
                    </NomeProfessor>
                  </span>
                </Professor>
                <StarsStyle className="mt-4 mt-lg-0  col-6 col-lg-2">
                  {stars.map((star, i) => (
                    <i key={`star-${i}`} className={`${star} fa-star ml-1`} />
                  ))}
                  <p> {evaluation}/5</p>
                </StarsStyle>
                <Views className="mt-4 mt-lg-0  col-6 col-lg-3">
                  <Icon className="fas fa-eye" />
                  {course.views}
                </Views>
              </Col>
            </Row>
            <Row>
              <Col xs="12" className="d-flex ">
                <ActionsStyle className="p-0 py-4 d-flex flex-wrap justify-content-lg-between align-items-center my-5 col-12">
                  <Col
                    xs="12"
                    lg="3"
                    className="px-3 pb-4 p-lg-0  d-flex justify-content-lg-between align-items-center">
                    {course.aulas.length > 0 && (
                      <AvalieStyle>
                        <b>Avalie este Curso</b>{' '}
                        {starsUser.map((star, i) => (
                          <i
                            key={`starAction-${i}`}
                            onClick={() => updateRating(i + 1)}
                            className={`${star} fa-star ml-1`}
                          />
                        ))}
                      </AvalieStyle>
                    )}
                  </Col>
                  <Col
                    xs="12"
                    lg="6"
                    className="p-0 pb-4 p-lg-0 d-flex justify-content-center align-items-center">
                    <ProgressCurso className="d-flex flex-wrap col-12">
                      <b>Progresso total do curso: </b>{' '}
                      <b className="ml-1">
                        {' '}
                        {` ${
                          progress.filter(
                            (stat) => stat.user_iri === getCurrentUser(),
                          ).length > 0
                            ? parseInt(
                                progress
                                  .filter(
                                    (stat) =>
                                      stat.user_iri === getCurrentUser(),
                                  )[0]
                                  .progress.toFixed(2),
                              )
                            : 0
                        }%`}
                      </b>
                      <Col
                        xs="12"
                        lg="6"
                        className="pt-2 pt-lg-0 d-flex align-items-center">
                        <ProgressBar
                          progress={
                            progress.filter(
                              (stat) => stat.user_iri === getCurrentUser(),
                            ).length > 0
                              ? progress.filter(
                                  (stat) => stat.user_iri === getCurrentUser(),
                                )[0].progress
                              : 0
                          }
                        />
                      </Col>
                    </ProgressCurso>
                  </Col>
                  <Col
                    xs="12"
                    lg="3"
                    className="d-flex justify-content-lg-end align-items-center">
                    <FavortitosStyle
                      onClick={updateFavorite}
                      className="d-flex justify-content-lg-end align-items-center">
                      <b>Adicione Aos Favoritos </b>{' '}
                      <i
                        className={`ml-2 ${
                          evaluationUserFavorite === '1' ? 'fas' : 'far'
                        } fa-heart`}
                      />
                    </FavortitosStyle>
                  </Col>
                </ActionsStyle>
              </Col>
            </Row>

            <Row>
              {typeof course.aulas !== 'undefined' &&
                course.aulas.length > 0 &&
                course.aulas.map((aula, i) => (
                  <Col key={`aula-${i}`} xs="12">
                    <Aula refreshProgress={setProgress} index={i} aula={aula} />
                  </Col>
                ))}
            </Row>

            {progress.filter((stat) => stat.user_iri === getCurrentUser())
              .length > 0 &&
              progress.filter((stat) => stat.user_iri === getCurrentUser())[0]
                .progress >= 100 &&
              course.exams.length > 0 && (
                <ProvaStyle className="d-flex flex-wrap justify-content-center  justify-content-lg-between ">
                  <h3 className="col-12 p-lg-0 col-lg-8 text-center text-lg-left">
                    Ao finalizar 100% de todas aulas deste curso, faça a prova e
                    receba o certificado.
                  </h3>
                  <Link onClick={(e) => openExam()} to="#">
                    Fazer prova agora
                  </Link>
                </ProvaStyle>
              )}
          </Container>
          {openExamModal === true && (
            <ModalExamSytled>
              <Container className="exam-style">
                <Row className="d-flex justify-content-end">
                  <button
                    onClick={(e) => openExam(closeModal)}
                    className="close-button">
                    <i className="fas fa-times"></i>
                  </button>
                </Row>

                <Exame
                  exams={course.exams}
                  typeExame={`course_id=${course.id}`}
                  openExam={openExam}
                  content={course}
                />
              </Container>
            </ModalExamSytled>
          )}
        </>
      )}
    </>
  );
};

CursoPageOnline.prototype = {
  course: PropTypes.object.isRequired,
};

export default CursoPageOnline;
