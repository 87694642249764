import { applyMiddleware, compose, createStore } from "redux";
import thunk from 'redux-thunk'
import rootReducer from './reducers'

const initialState = {};

const store = createStore(rootReducer, initialState, compose(
  applyMiddleware(thunk)
));

export default store;
