import React, {  useContext} from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { TitleVideo, FeaturedBoxVideo,NextVideo,VideoStyle} from './styles';
import {HandleRefreshContextVideo} from './handleRefreshContext';

const  SingleVideo = ({aula,...props}) => {
  const { changeVideo } = useContext(HandleRefreshContextVideo);

  
  return (
    <NextVideo>
    <Row className="pt-4 pb-4 mx-0 video-line" onClick={e => changeVideo(aula.position)}>
      <Col xs="4" className="d-flex align-items-center p-0 ">
        <FeaturedBoxVideo>
          {/* <img src={aula.thumbnail} alt={aula.titleVideo}/> */}

          {/* {aula.type === "SAMBA_MEDIAss" &&
              <SambaVideo reference={aula.samba_media_reference} />} */}
          {aula.type === "FILE" &&
            <VideoStyle >
              <source src={aula.urlPublica} type="video/mp4"/>
            </VideoStyle>}
        </FeaturedBoxVideo>
      </Col>
      <Col xs="8">
        <TitleVideo>{aula.title}</TitleVideo>
      </Col>
    </Row>
    </NextVideo>
  );
};
SingleVideo.propTypes ={
    aula: PropTypes.object.isRequired
}


export default SingleVideo;
