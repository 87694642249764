import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import InternalTitle from '../../components/Theme/Title/InternalTitle';
import CourseItem from '../../components/Home/Cursos/Distancia/CourseItem';
import {CourseBox} from './styles';
// import photo from '../../assets/images/photo.png';
import {Container, Col, Row} from 'reactstrap';
import api from '../../services/api';
// import { toSnakeCase } from '../../utils/toSnakeCase'
import Slider from 'react-slick';
import {FilterStyle} from './styles';
import userPlaceholder from '../../assets/images/user_placeholder.png';

const Trilhas = () => {
  const [trilhas, setTrilhas] = useState([]);

  useEffect(() => {
    api
      .get(`/lms/trails`)
      .then((trails) => {
        let arrayTrilhas = [];
        trails.data.forEach((trail) => {
          let dataCourses = [];

          trail.trails_courses.forEach((trail_course, i) => {
            let element = trail_course.course;
            if (element) {
              dataCourses.push({
                _id: `/lms/courses/${element.id}`,
                id: element.id,
                image: {
                  urlPublica:
                    element.image !== null
                      ? element.image.urlPublica
                      : userPlaceholder,
                },
                nota: 5 || '',
                duration: element.duration || '',
                data: element.created_at || '',
                views: element.views || '',
                photo:
                  element.hasOwnProperty('created_by') &&
                  element.created_by.hasOwnProperty('profile') &&
                  element.created_by.profile.hasOwnProperty('avatar') &&
                  element.created_by.profile.avatar !== undefined &&
                  element.created_by.profile.avatar.file != null
                    ? element.created_by.profile.avatar.file.urlPublica
                    : userPlaceholder,
                autor: element.hasOwnProperty('created_by')
                  ? element.created_by.name
                  : '',
                titulo: element.title || '',
                description: element.summary || '',
                url: `/trails/${trail.id}?course_id=${i}`,
              });
            }
          });
          arrayTrilhas.push(
            Object.assign(trail, {titulo: trail.title, cursos: dataCourses}),
          );
        });

        setTrilhas(arrayTrilhas);
      })
      .catch((err) => {
        console.error('Erro ao buscar Trilhas: ', err);
      });

    return () => {
      setTrilhas([]);
    };
  }, []);
  // const [loading, setLoading] = useState(false);
  const [qtd, setQtd] = useState(2);
  const [filter, setFilter] = useState('');

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  function expand() {
    setQtd((prevState) => (prevState = prevState + 1));
  }
  return (
    <Base>
      <Title value="Trilhas" />
      <Container className="p-0">
        <FilterStyle className="col-12 p-0 d-flex search-filter  d-flex justify-content-end">
          <div className="searchFilter col-12 col-md-6 col-lg-3 p-0">
            <input
              onKeyUp={(event) => setFilter(event.target.value)}
              className="w-100"
              placeholder="Digite aqui.."
              type="text"
            />
            <i className="fas fa-search " />
          </div>
        </FilterStyle>
        {trilhas.length > 0 && (
          <>
            {trilhas.slice(0, qtd).map((trilha, i) => (
              <>
                {trilha.cursos.length > 0 &&
                  trilha.cursos.every((item) => item.course == null) && (
                    <Row key={`TrilhaRow-${i}`}>
                      <InternalTitle
                        key={`TrilhaTitle-${i}`}
                        value={trilha.titulo}
                      />
                      <Col
                        xs="12"
                        key={`TrilhaItem-${i}`}
                        className="CourseBlock">
                        {trilha.cursos.length > 0 && (
                          <Slider {...settings}>
                            {trilha.cursos.map((course, j) => {
                              return filter === '' ||
                                course.titulo
                                  .toLowerCase()
                                  .indexOf(filter.toLowerCase()) > -1 ? (
                                <CourseBox key={`courseItem-${j}`}>
                                  <span className="trilha"></span>
                                  <CourseItem course={course} />
                                </CourseBox>
                              ) : (
                                ''
                              );
                            })}
                          </Slider>
                        )}
                      </Col>
                    </Row>
                  )}
              </>
            ))}
          </>
        )}
        {trilhas.length > 0 && trilhas.length > qtd ? (
          <Row className="p-4 p-lg-0">
            <button onClick={expand} className="col-12 col-lg-3 LoadMoreButtom">
              Carregar Mais
            </button>
          </Row>
        ) : (
          ''
        )}
      </Container>
    </Base>
  );
};
export default Trilhas;
