import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { QuestionStyle, QuestionActions, UserPhotoStyle } from './styles';
import { Col } from 'reactstrap';
import stripTags from 'striptags';
import NewQuestion from './NewQuestion';
import format from '../../../utils/format';
import ContainerLoading from '../../Theme/Loading';

const Question = ({ pergunta, ...props }) => {
  const [newAnswer, setNewAnswer] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = {
    photo: atob(localStorage.getItem(`user-photo`)),
    nome: atob(localStorage.getItem(`user-name`)),
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(true);
    }, 1000);
  });
  return loading === false ? (
    <ContainerLoading />
  ) : (
      <>
        <QuestionStyle className="row d-flex justify-content-between my-3">
          <UserPhotoStyle className="col-2 col-md-1 pr-0">
            <div className="user-img">
              {typeof pergunta.user.photo != 'undefined' && (
                <img src={pergunta.user.photo} alt={pergunta.user.name} />
              )}
            </div>
          </UserPhotoStyle>
          <div className="col-10 col-md-11 box-question">
            <div className="content-question ">
              <Col
                lg="12"
                className="headerQuestion p-0 mb-3 d-flex justify-content-lg-between">
                <h4>{pergunta.user.name}</h4>
                <span>
                  {format.data(pergunta.data, 'numberDay')}{' '}
                  {format.data(pergunta.data, 'stringMonth').slice(0, 3)}{' '}
                  {format.data(pergunta.data, 'numberYear')} {' | '}
                  {format.data(pergunta.data, 'hour')}{' '}
                </span>
              </Col>
              <div className="questionContent" dangerouslySetInnerHTML={{ __html: pergunta.content }} />
            </div>
            <QuestionActions className="mt-2">
              {pergunta.question &&
                <button
                  onClick={() => setNewAnswer((prev) => !prev)}
                  className="btn-resp">
                  Responder
              </button>}
              {pergunta.answers.length > 0 ? (
                <label htmlFor={`answer-${props.index}`} className="btn-see-resp">
                  Ver Respostas ({pergunta.answers.length}){' '}
                  <i className="fas fa-sort-down" />
                </label>
              ) : (
                  ''
                )}
            </QuestionActions>
            <input
              id={`answer-${props.index}`}
              type="checkbox"
              value="answer"
              className="d-none"
            />
            {pergunta.answers.length > 0 && (
              <span className={`mr-5 sub-resp ${newAnswer ? 'active' : ''}`}>
                <NewQuestion
                  answer={true}
                  parent={pergunta.id}
                  lesson_id={pergunta.lesson_id}
                  user={user}
                />
                {pergunta.answers.map((sub_perg, i) => (
                  <Question key={`qt-${i}`} pergunta={sub_perg} />
                ))}
              </span>
            )}
            {pergunta.answers.length <= 0 && (
              <span className={`mr-5 sub-resp ${newAnswer ? 'active' : ''}`}>
                <NewQuestion
                  answer={true}
                  parent={pergunta.id}
                  lesson_id={pergunta.lesson_id}
                  user={user}
                />
              </span>
            )}
          </div>
        </QuestionStyle>
      </>
    );
};
Question.propTypes = {
  pergunta: PropTypes.object.isRequired,
  index: PropTypes.string,
};

export default Question;
