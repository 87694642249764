import React, { useState, useEffect } from 'react';
import Base from '../../../components/Theme/base';
import Title from '../../../components/Theme/Title';
// import Filter from '../../../components/Theme/Filter';
import CourseItem from '../../../components/Home/Cursos/Distancia/CourseItem';
import { Container, Col, Row, Spinner } from 'reactstrap';
import { useDebounce } from 'use-debounce';
import api from '../../../services/api';
import userPlaceholder from './../../../assets/images/athie_placeholder.png';
import { FilterStyle } from '.././../../components/Theme/Filter/styles';
// import stripTags from 'striptags';

const CursoDistancia = () => {
  const perPage = 8;
  const [courses, setCourses] = useState(null);
  const [filter, setFilter] = useState('');
  const [filterDebounce] = useDebounce(filter, 1000);
  // const [filterType, setFilterType] = useState('titulo');
  const [page, setPage] = useState(0);
  const [countPage, setCountPage] = useState(0);



  const getCountPage = async () => {
    try {

      const response = await api.get(`/lms/courses/count_by`, { params: { 'course_search': filter } });
      const { data } = response;

      if (data % perPage >= 1) {
        return Math.trunc(data / perPage) + 1;
      } else {
        return Math.trunc(data / perPage);
      }
    } catch (error) {
      console.error('CursoDistancia.getCountPage', error);
      return 0;
    }
  };

  const loadMore = async (page) => {

    const more = [];
    try {

      const response = await api.get(
        `/lms/courses`, {
        params: {
          'order[highlight asc,courses.published_at]': 'desc',
          'perPage': perPage,
          'page': page,
          'course_search': filter
        }
      },
      );
      response.data.forEach((element) => {
        if (element.lessons.length > 0) {
          more.push({
            _id: `/lms/courses/${element.id}`,
            id: element.id,
            image: {
              urlPublica:
                element.image !== null
                  ? element.image.urlPublica
                  : userPlaceholder,
            },
            nota: 5,
            duration: element.duration,
            data: element.created_at,
            views: element.views,
            photo:
              element.hasOwnProperty('profile') &&
                element.created_by.profile.hasOwnProperty('avatar') &&
                element.created_by.profile.avatar.file != null
                ? element.reated_by.profile.avatar.file.urlPublica
                : userPlaceholder,
            autor: element.hasOwnProperty('created_by')
              ? element.created_by.name
              : '',
            titulo: element.title,
            description: element.summary,
            url: `/online/lms/courses/${element.id}`,
          });
        }
      });
      setCourses(courses.concat(more));
    } catch (error) {
      console.error('CursoDistancia.loadMore', error);
    }
  };

  const getCourses = async (params) => {
    setCourses(null);
    const temp = [];
    try {
      const response = await api.get(
        `/lms/courses`, {
        params
      });

      response.data.forEach((element) => {
        if (element.lessons.length > 0) {
          temp.push({
            _id: `/lms/courses/${element.id}`,
            id: element.id,
            image: {
              urlPublica:
                element.image !== null
                  ? element.image.urlPublica
                  : userPlaceholder,
            },
            nota: 5,
            duration: element.duration,
            evaluations: element.evaluations,
            data: element.created_at,
            views: element.views,
            photo:
              element.hasOwnProperty('profile') &&
                element.created_by.profile.hasOwnProperty('avatar') &&
                element.created_by.profile.avatar.file != null
                ? element.reated_by.profile.avatar.file.urlPublica
                : userPlaceholder,
            autor: element.hasOwnProperty('created_by')
              ? element.created_by.name
              : '',
            titulo: element.title,
            description: element.summary,
            url: `/online/lms/courses/${element.id}`,
          });
        }
      });
      return temp;
    } catch (error) {
      console.error('CursoDistancia.getCourses', error);
      return [];
    }
  };

  useEffect(() => {
    const params = {
      'order[highlight asc,courses.published_at]': 'desc',
      'perPage': perPage,
      'page': page,
      'course_search': filter
    };
    (async () => {
      setCountPage(await getCountPage());
      setCourses(await getCourses(params));
    })();
  }, [filterDebounce]);

  return (
    <Base>
      <Title value="Cursos Online" />
      <Container className="p-0 my-5">
        <FilterStyle>
          <Row className="d-flex col-12 justify-content-end p-0 m-0">
            {/* <Col xs='12' md="2" className="p-0 px-md-2">
              <select onChange={(e) => setFilterType(e.target.value)} name="select" className="w-100">
                <option value="titulo">Título</option>
                <option value="description">Descrição</option>
              </select>
            </Col> */}

            <Col xs="12" md="3" className="p-0 d-flex search-filter">
              <input
                onKeyUp={(event) => { setFilter(event.target.value); setPage(0); }}
                className="w-100"
                placeholder="Digite aqui.."
                type="text"
              />
              <i className="fas fa-search " />
            </Col>
          </Row>
        </FilterStyle>
      </Container>
      <Container className="p-0">
        <Row>
          {courses &&
            courses.map((course, i) =>
              <Col
                key={`courseItem${i}`}
                xs="12"
                md="6"
                lg="3"
                className="mb-4 p-md-0">
                {' '}
                <CourseItem key={`courseItem${i}`} course={course} />{' '}
              </Col>
            )}
        </Row>
        {courses && courses.length === 0 && (
          <Row>
            <h1>Nenhum Curso disponível</h1>
          </Row>
        )}
        {!courses && (
          <div className="mt-30 mb-30 text-center">
            <Spinner />
          </div>
        )}
        {page < countPage ? (
          <Row className="p-4 p-lg-0">
            <button
              onClick={() => { setPage((prev) => prev + 1); loadMore(page + 1); }}
              className="col-12 col-lg-3 LoadMoreButtom">
              Carregar Mais
            </button>
          </Row>
        ) : (
            ''
          )}
      </Container>
    </Base>
  );
};
export default CursoDistancia;
