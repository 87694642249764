import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem as MenuItemStyle, MenuLink } from './styles';



const MenuItem = (props) => {
  


  return (
    <MenuItemStyle className={window.location.pathname.indexOf(props.url) > -1?'active':''}>
        <MenuLink to={props.url}>{props.title}</MenuLink>
    </MenuItemStyle>   
  );
};

MenuItem.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default MenuItem;
