import React, {useEffect,useState} from 'react';
import { Footer as FooterStyled,TextLink,Text } from './styles';
import {  Col, Row } from 'reactstrap';
import Logo from '../../../assets/images/footer-logo.jpg';
import api from '../../../services/api';


const Footer = () => {

  const [menus, setMenus] = useState([]);
  useEffect( ()=>{

    const data = [];
    api.get('/cms/menus/2').then( (res) => {
      
      res.data.menu_items.forEach(element => {
        
          data.push({
             title:element.title,
             url: element.url
            })
            
      })
      setMenus(data);
    })
    .catch((err) => {
      console.error('Erro ao buscar Cursos: ', err);
    })
    return () => {
      setMenus([]);
    };
  }, []);

  return (
    <FooterStyled className="box-footer">
        <Row className="w-100">
          <Col xs="12" className="d-flex flex-wrap justify-content-center">
            <Col xs="12" lg="3" className="d-flex justify-content-center p-0">
              <img className="col-8 col-lg-12" src={Logo} alt="logo"/>
            </Col>
            <Col xs="12" lg="9" className="d-flex flex-wrap justify-content-center justify-content-lg-end align-items-center p-0">
              <Text className="p-0 d-flex justify-content-center justify-content-lg-end col-12 col-lg-4 text-lg-right">Copyright 2019 Athie Wohnrath</Text>
              {menus.map((menu,i) => <TextLink key={`menu-item-${i}`} to={menu.url}  >{menu.title}</TextLink> )} 
            </Col>
          </Col>
        </Row>
    </FooterStyled>
  );
};

export default Footer;
