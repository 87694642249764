import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { VideoStyle } from '../Cursos/Online/styles';
import api from '../../services/api';
import ContextTrail from '../Cursos/Online/contextTrail';

const VideoFile = ({ url, refreshProgress, id, lesson_content_iri }) => {
    const setReload = useContext(ContextTrail);
    const [progress, setProgress] = useState(false);
    const [watchedId] = useState(lesson_content_iri);

    function handleProgress({ target }) {

        if (parseInt(target.currentTime) % 15 === 0 && !progress) {
            const progresso = parseInt((target.currentTime / target.duration) * 100);
            setProgress(true);
            if (watchedId === null) {
                api.post(`/general/watcheds`, {
                    user_iri: atob(localStorage.getItem(`user-endpoint`)),
                    progress: progresso,
                    content_iri: `/lms/lesson_contents/${id}`,
                });
            }
            setTimeout(() => setProgress(false), 1000);
        }
    }

    function handleEnded() {
        api.post(`/general/watcheds`, {
            user_iri: atob(localStorage.getItem(`user-endpoint`)),
            progress: 100,
            content_iri: `/lms/lesson_contents/${id}`,
            watched: true,
        }).then(() => {
            if (setReload) {
                setReload(prev => !prev);
            }
            refreshProgress(id);
        });
    }

    return (
        <VideoStyle controls onEnded={handleEnded} onTimeUpdate={handleProgress}>
            <source id="featuredVideo" src={url} type="video/mp4" />
        </VideoStyle>
    )
};

VideoFile.propTypes = {
    url: PropTypes.string.isRequired
}
export default VideoFile;