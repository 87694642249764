import api from '../../../services/api';
import getCurrentUser from '../../../utils/getCurrentUser';
import userPlaceholder from '../../../assets/images/user_placeholder.png';

export const mountTrail = (res, response) => {
  let lessons = [];
  if (res.course.lessons.length > 0) {
    //listagem de videos
    res.course.lessons.forEach((item) => {
      let videos = [];
      let material = [];

      if (item.lesson_contents.length > 0) {
        let contents = item.lesson_contents || [];

        contents.forEach((video, i) => {
          api
            .get(
              `/general/watcheds?content_iri=/lms/lesson_contents/${video.id}`,
            )
            .then((progress) => {
              videos.push({
                lessonContent: video.id,
                type: video.type || '',
                position: i,
                watched_id:
                  progress.data.length > 0 ? progress.data[0].id : null,
                urlPublica: video.file !== null ? video.file.urlPublica : '',
                title: video.title,
                contentType: '',
                samba_media_reference:
                  video.samba_media_reference !== null
                    ? video.samba_media_reference
                    : '',
                vimeo_media_reference:
                  video.vimeo_media_reference !== null
                    ? video.vimeo_media_reference
                    : '',
              });
            })
            .catch((erro) => {
              console.error(erro);
            });
        });
      }
      if (item.lesson_support_materials.length > 0) {
        //listagem de materiais
        let suport_material_array = item.lesson_support_materials
          ? item.lesson_support_materials
          : [];

        suport_material_array.forEach((suport_material) => {
          let suport_material_item = suport_material.support_material;
          material.push({
            title: suport_material_item.title,
            url:
              suport_material_item.support_material_contents[0].file.urlPublica,
          });
        });
      }
      lessons.push({
        _id: `/lms/lessons/${item.id}`,
        id: item.id,
        title: item.title,
        lesson_stats: item.lesson_stats,
        progress:
          item.lesson_stats.length > 0 &&
          item.lesson_stats.filter(
            (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
          ).length > 0
            ? item.lesson_stats.filter(
                (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
              )[0].progress
            : 0,
        descricao: item.description,
        course_iri: '/lms/courses/' + res.course.id,
        course_id: res.course.id,
        complemento: material,
        videoaula: videos,
        exams: item.lesson_exams || [],
      });
    });
  }

  var course = {
    _id: `/lms/courses/${res.course.id}`,
    id: res.course.id,
    image: {
      urlPublica:
        res.course.image !== null
          ? res.course.image.urlPublica
          : userPlaceholder,
    },
    nota: 5,
    course_stats: res.course.course_stats,
    progressoTotal:
      res.course.course_stats.length > 0
        ? res.course.course_stats[0].progress
        : 0,
    duration: res.course.duration,
    data: res.course.created_at,
    views: res.course.views,
    photo:
      res.course.hasOwnProperty('profile') &&
      res.course.created_by.profile.hasOwnProperty('avatar') &&
      res.course.created_by.profile.avatar.file != null
        ? res.course.reated_by.profile.avatar.file.urlPublica
        : userPlaceholder,
    autor:
      res.course.hasOwnProperty('profile') &&
      res.course.created_by.profile.hasOwnProperty('avatar') &&
      res.course.created_by.name !== undefined
        ? res.course.created_by.name
        : '',
    titulo: res.course.title,
    description: res.course.summary,
    url: `/trails/${response.data.id}`,
    aulas: lessons,
    exams: res.course.course_exams.length > 0 ? res.course.course_exams : [],
  };
  return course;
};
