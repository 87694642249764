import React, {useState} from 'react';
import { Carousel } from 'react-responsive-carousel';
import BannerImg from './bannerImg';
import PropTypes from 'prop-types';


const BannerCarousel = (banners) => {
const [bannerList] = useState(banners.content.banners.length > 0 ? banners.content.banners:[]);
  return(
   
      <Carousel classname='Banner-Home' showIndicators={false} showStatus={false} showThumbs={false}>
        { bannerList.length > 0 &&
          bannerList.map((banner, i) => <BannerImg key={`banner-img-${i}`}  image={banner.file.urlPublica} title={banner.title} description={banner.alternate_text} url={banner.url} /> )}        
      </Carousel>
    
  )
};

BannerCarousel.propTypes = {
  banners: PropTypes.array
}
export default BannerCarousel;


