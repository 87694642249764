import React, {useState, useLayoutEffect, useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Container} from 'reactstrap';
import ProgressBar from '../../../components/Theme/ProgressBar';
import SingleVideo from './SingleVideo';
import Anexo from './Anexo';
import Question from './Questions';
import NewQuestion from './NewQuestion';
import {
  BlockSection,
  DescriptionSection,
  ModalExamSytled,
  LikeBar,
  BoxNextVideos,
  ContentStyle,
  RowAula,
  NumberAula,
  TitleAula,
  ProgressAula,
  LabelButtom,
} from './styles';
import api from '../../../services/api';
import userPlaceholder from '../../../assets/images/user_placeholder.png';
import {
  HandleRefreshContext,
  HandleRefreshContextVideo,
} from './handleRefreshContext';
import PlayerVideo from '../../ContentMedia';
import Exame from '../../Exames';
import getCurrentUser from '../../../utils/getCurrentUser';
import ContextTrail from './contextTrail';

const Aula = ({aula, ...props}) => {
  const setReload = useContext(ContextTrail);
  const closeModal = true;
  const [questions, setQuestions] = useState([]);
  const [actualVideo, setActualVideo] = useState(0);
  const [like, setLike] = useState('none');
  const [expand, setExpand] = useState(props.index === 0);
  const [openExamModal, setOpenExamModal] = useState(false);
  const [progressLesson, setProgressLesson] = useState(0);
  const [refreshProgress, setRefreshProgress] = useState(0);

  const getProgressLesson = () => {
    api
      .get(aula._id)
      .then((res) => {
        let progresso =
          res.data.lesson_stats.length > 0 &&
          res.data.lesson_stats.filter(
            (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
          ).length > 0
            ? res.data.lesson_stats.filter(
                (lesson_stat) => lesson_stat.user_iri === getCurrentUser(),
              )[0].progress
            : 0;
        setProgressLesson(progresso);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos', err);
      });
  };
  const getProgressCourse = () => {
    api
      .get(aula.course_iri)
      .then((res) => {
        props.refreshProgress(res.data.course_stats);
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos', err);
      });
  };

  function openExam(close = false) {
    setOpenExamModal(!openExamModal);
    if (close && setReload) {
      setReload((prev) => !prev);
    }
  }

  function changeVideo(idVideo) {
    setActualVideo(idVideo);
  }

  function commentsMount(questionList) {
    let questions = [];
    questionList.forEach((element) => {
      let temp = [];
      if (element.course_answers && element.course_answers.length > 0) {
        temp = commentsMount(element.course_answers);
      }
      questions.push({
        _id: `/lms/course_questions/${element.id}`,
        id: element.id,
        lesson_id: aula.id,
        user: {
          name:
            element !== undefined && element.hasOwnProperty('user')
              ? element.user.name
              : 'Sem Nome',
          photo:
            element !== undefined &&
            element.hasOwnProperty('user') &&
            element.user.hasOwnProperty('profile') &&
            element.user.profile.hasOwnProperty('avatar') &&
            element.user.profile.avatar.file != null
              ? element.user.profile.avatar.file.urlPublica
              : userPlaceholder,
        },
        data: element.created_at,
        content: element.hasOwnProperty('question')
          ? element.question
          : element.answer,
        answers: temp,
        question: element.hasOwnProperty('question') ? true : false,
      });
    });

    return questions;
  }
  function updateLike(value) {
    let endpoint = `/general/evaluations`;
    let data = {
      user_iri: atob(localStorage.getItem(`user-endpoint`)),
      content_iri: `${aula._id}/lesson_contents/${aula.videoaula[actualVideo].lessonContent}`,
      value: value,
      type: 'LIKE',
    };

    api.post(endpoint, data).then((res) => {
      if (res.data !== '' && res.data.type === 'LIKE') {
        setLike(res.data.value);
        setExpand((prev) => prev);
      }
    });
  }
  function getLikeVideo(index) {
    let endpoint = `/general/evaluations?content_iri=${aula._id}/lesson_contents/${aula.videoaula[index].lessonContent}`;
    api.get(endpoint).then((res) => {
      if (res.data.length > 0 && res.data[0].type === 'LIKE') {
        setLike(res.data[0].value);
      } else {
        setLike(-1);
      }
    });
  }
  function handleRefreshComments() {
    api
      .get(`/lms/course_questions?wherein[lesson.id]=${aula.id}&order[id]=Desc`)
      .then((response) => {
        if (response.data.length) {
          setQuestions(commentsMount(response.data));
        }
      });
  }

  useEffect(() => {
    getProgressLesson();
    getProgressCourse();
  }, [refreshProgress, aula]);

  useLayoutEffect(() => {
    handleRefreshComments();
    if (aula.videoaula.length > 0) {
      getLikeVideo(actualVideo);
    }
  }, [actualVideo, expand]);

  const user = {
    photo: atob(localStorage.getItem(`user-photo`)),
    nome: atob(localStorage.getItem(`user-name`)),
  };

  return (
    <BlockSection className="aula">
      <RowAula className={`d-flex flex-wrap ${expand ? 'expand' : ''}`}>
        <NumberAula className="p-0 d-flex justify-content-center py-2 col-12 col-md-1">
          Aula{' '}
          {props.index + 1 < 10 ? '0' + (props.index + 1) : props.index + 1}
        </NumberAula>
        <TitleAula className="col-6 col-md-8">
          <h4>{aula.title}</h4>
        </TitleAula>
        <ProgressAula className="col-3 col-md-2">
          <Col lg="12" className="p-0 d-flex align-items-center">
            <h4 className="progressTitle m-0 mr-1">
              {parseInt(progressLesson.toFixed(2))}%
            </h4>
            <ProgressBar progress={progressLesson} />
          </Col>
        </ProgressAula>
        <LabelButtom
          onClick={(e) => setExpand((prev) => !prev)}
          htmlFor={`check-${props.index}`}
          data-index={props.index}
          className="m-0 p-0 col-1 d-flex align-items-center justify-content-center">
          <i className="fal fa-plus-circle" />
        </LabelButtom>
        <input
          id={`check-${props.index}`}
          checked={expand}
          type="checkbox"
          value="expand"
          name="input-expand "
          className="input-expand d-none"
        />
        <ContentStyle className="col-12  flex-wrap expand-content">
          <h2 className="pl-0 mt-5 mb-4 col-12">{aula.title}</h2>
          <h5>
            <b>Vídeo </b>
            {actualVideo + 1} de {aula.videoaula.length}{' '}
          </h5>
          <Row className="w-100">
            <Col lg={aula.videoaula.length > 1 ? 8 : 12}>
              {expand && aula.videoaula.length > 0 && (
                <PlayerVideo
                  refreshProgress={setRefreshProgress}
                  video={aula.videoaula[actualVideo]}
                />
              )}
              <LikeBar className="d-flex align-items-center justify-content-end">
                <button onClick={(e) => updateLike(1)}>
                  <i
                    className={`${like === '1' ? 'fas' : 'far'} fa-thumbs-up`}
                  />
                </button>
                <button onClick={(e) => updateLike(0)}>
                  <i
                    className={`${
                      like === '0' ? 'fas' : 'far'
                    } fa-thumbs-down`}></i>
                </button>
              </LikeBar>
            </Col>
            {aula.videoaula.length > 1 && (
              <Col lg={4}>
                <h4>Próximos videos desta aula</h4>
                <HandleRefreshContextVideo.Provider value={{changeVideo}}>
                  <BoxNextVideos>
                    {aula.videoaula.map((aula, i) => (
                      <SingleVideo key={`video-${i}`} aula={aula} />
                    ))}
                  </BoxNextVideos>
                </HandleRefreshContextVideo.Provider>
              </Col>
            )}
          </Row>
          <DescriptionSection className="row w-100">
            <Col lg={8}>
              <div className="description">
                <h2 className="my-4">Descrição</h2>
                <p dangerouslySetInnerHTML={{__html: aula.descricao}} />
              </div>
              <div className="questions">
                <h2 className="my-4">Envie sua pergunta</h2>
                <HandleRefreshContext.Provider value={{handleRefreshComments}}>
                  <div className="list-questions d-flex flex-column pr-4">
                    <NewQuestion
                      answer={false}
                      course_id={aula.course_id}
                      lesson_id={aula.id}
                      user={user}
                    />
                    {typeof questions !== 'undefined' &&
                      questions.length > 0 &&
                      questions.map((question, i) => (
                        <Question
                          key={`question-${i}`}
                          index={`${aula.id}-${i}`}
                          pergunta={question}
                        />
                      ))}
                  </div>
                </HandleRefreshContext.Provider>
              </div>
            </Col>
            <Col lg="4">
              {!!aula.complemento.length &&
                aula.complemento[0].hasOwnProperty('title') &&
                aula.complemento[0].title !== '' && (
                  <>
                    <h2 className="my-4">Material Complementar</h2>
                    <small>Baixe o material complementar desta aula</small>
                    <div className="anexos">
                      {aula.complemento.map((anexo, i) => (
                        <Anexo
                          key={`att-${i}`}
                          url={anexo.url}
                          title_file={anexo.title}
                        />
                      ))}
                    </div>
                  </>
                )}
              {progressLesson >= 100 && aula.exams.length > 0 && (
                <button
                  onClick={(e) => openExam()}
                  className="col-12 LoadMoreButtom">
                  Fazer prova dessa Aula Agora
                </button>
              )}
            </Col>
          </DescriptionSection>
        </ContentStyle>
      </RowAula>
      {openExamModal === true && (
        <ModalExamSytled>
          <Container className="exam-style">
            <Row className="d-flex justify-content-end">
              <button
                onClick={(e) => openExam(closeModal)}
                className="close-button">
                <i className="fas fa-times"></i>
              </button>
            </Row>

            <Exame
              exams={aula.exams}
              typeExame={`lesson_id=${aula.id}`}
              openExam={openExam}
              content={aula}
            />
          </Container>
        </ModalExamSytled>
      )}
    </BlockSection>
  );
};

Aula.propTypes = {
  aula: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};
export default Aula;
