import React from 'react';
import { SubTitleStyle } from './styles';
import PropTypes from 'prop-types';
import {  Container } from 'reactstrap';


const SubTitle = (props) => {
 
  return (
    <Container className="p-0">
      <SubTitleStyle>{props.value}</SubTitleStyle>
    </Container>
    
  );
};
SubTitle.prototype = {
  value:PropTypes.string.isRequired
}

export default SubTitle;