import React, { useState, useEffect } from 'react';
import CourseItem from './CourseItem';
import photo from '../../../../assets/images/photo.png';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { BlockSection, TitleCursos, BoxCursos } from './style';
import Slider from "react-slick";
import api from '../../../../services/api';
import userPlaceholder from '../../../../assets/images/user_placeholder.png'

const BlockCursos = (props) => {
  const [courses, setCourses] = useState([]);
  const coursesContent = props.content;
  useEffect(() => {

    const data = [];
    if (coursesContent.length > 0) {
      coursesContent.forEach(element => {
        if (element.lessons.length > 0) {
          data.push({
            _id: `/lms/courses/${element.id}`,
            id: element.id,
            image: {
              urlPublica: element.image !== null ? element.image.urlPublica : userPlaceholder
            },
            nota: 5,
            duration: element.duration || '',
            data: element.created_at || '',
            views: element.views,
            photo: userPlaceholder,
            autor: element.hasOwnProperty('created_by') ? element.created_by.name : '',
            titulo: element.title || '',
            description: element.summary || '',
            url: `online/lms/courses/${element.id}`
          })
        }
      })
    }
    setCourses(data);

    return () => {
      setCourses([]);
    };
  }, []);



  var settings = {
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };
  return (
    <BlockSection className="container CourseBlock p-0">
      {courses.length > 0 && (
        <Row>
          <Col xs='12' lg="3" className="d-flex flex-wrap flex-lg-nowrap align-items-start">
            <TitleCursos>Cursos</TitleCursos>
            <BoxCursos className="col-12 col-lg-8">
              <h3>{props.block.title ? props.block.title : "Cursos Online"}</h3>
              <p>Ver todos</p>
              <Link to='/online/lms/courses'><i className="far fa-arrow-alt-circle-right" /></Link>
            </BoxCursos>
          </Col>
          <Col xs='12' lg="9" className="p-lg-0 slider">

            <Slider {...settings}>
              {courses.slice(0, 9).map((course, i) =>
                <CourseItem key={`courseItem-${i}`} course={course} />
              )}
            </Slider>
          </Col>
        </Row>
      )}
    </BlockSection>
  );
};


export default BlockCursos;
