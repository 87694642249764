import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const TitleBanner = styled.h4`
  padding: 0px;
  font-size: 33px;
  text-align: left;
  color: #fff;
  font-weight: 100;
  @media(max-width:991px){
      font-size: 1em;
  }
`;
export const BannerItem = styled.div`
  margin:60px;
  padding: 30px;
  background-color: #000000c2;
  border-radius: 0; 
  max-width: 360px;
  top: 0px;
  bottom: unset;
  position: absolute;
  text-align: left;
  @media(max-width:991px){
      margin: 0px 0;
      max-width: 100%;
      height:100%;
  }
`;
export const DescriptionBanner = styled.p`
  text-align: left;
  font-size: 1em;
  color: #fff;
  @media(max-width:991px){
      font-size: .6em;
  }
`;
export const Banner = styled.div`
  &.Banner-item{
    height: 100%;
    & img{
      height: 100%;
      object-fit: cover;
    }
  }
`;
export const BannerLink = styled(Link)`
  color:#fff;
  font-size: 1.6em; 
  font-weight: 500;   
  text-align: left;
    &:hover {
      text-decoration:none;
    }
`;



