import React,{ useState, useEffect } from 'react';
import Base from '../../../components/Theme/base';
import Title from '../../../components/Theme/Title';
import CourseItem from '../../../components/Home/Cursos/Presencial/CourseItem';
import {  Container, Col,Row } from 'reactstrap';
import api from '../../../services/api';
import photo from '../../../assets/images/athie_placeholder.png';


const CursoDistancia = () =>{
  const [courses, setCourses] = useState([]);


  useEffect( ()=>{

    const data = [];
    api.get('/lms/classroom_courses?order[highlight asc,published_at ]=desc').then( (res) => {
      res.data.forEach(element => {

          data.push({
              _id: `/lms/classroom_courses${element.id}`,
              id: element.id,
              image: {
                file:{
                  urlPublica:photo
                }
              },
              nota: 5,
              duration: element.duration,
              data: element.published_at,
              views:'845',
              photo: photo,
              titulo: element.title,
              description: element.summary,
              url: `/presencial/lms/classroom_courses/${element.id}`
            })

      })
      setCourses(data);
    })
    .catch((err) => {
      console.error('Erro ao buscar Cursos: ', err);
    })



    return () => {
      setCourses([]);
    };
  }, []);

  const [qtd,setQtd] = useState(4);
    function expand(e){
      setQtd(prevState => prevState = prevState + 4);
    }
  return(
    <Base>
      <Title value="Cursos Presenciais"/>
      <Container className="p-0">
        <Row>
           {courses.slice(0,qtd).map((course, i) =>
                <Col key={`courseItem${i}`} xs="12" md="6" lg="3" className="mb-4 py-1">  <CourseItem key={`courseItem${i}`} course={course} /> </Col>
            )}
        </Row>
        {courses.length > 0 && courses.length > qtd ?
                  <Row className="p-4 p-lg-0">
                    <button onClick={expand} className="col-12 col-lg-3 LoadMoreButtom">Carregar Mais</button>
                  </Row>:''}
      </Container>
    </Base>
  )

};
export default CursoDistancia;
