import styled from 'styled-components';

export const ModalExamSytled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.82);
    width: 100%;
    height: 100vh;
    z-index: 9999;
    display: flex;
    align-items: center;
    .has-float-label label {
        top: -20px;
    }
    .exam-style{    
        background-color: #fff;
        border-radius: 8px;
        padding: 40px 40px!important;
           & button#next-question {
            right: 0;
            position: absolute;
          }
          
          & button#prev-question {
            bottom: -44px;
            position: absolute;
          }
          & button#submitAnswers {
            width: 100%;
            max-width: 100%;
            top: 60px;
            background-color: #a58a77;
          }
         & button.close-button {
            border: none;
          }
          & .slick-disabled{
              opacity:0;
              visibility:hidden;
          }
    }
    
`;