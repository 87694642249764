import React from 'react';
import PropTypes from 'prop-types';
import { Banner, BannerLink, DescriptionBanner, TitleBanner, BannerItem } from './style'



const BannerImg = (props) => {
  return(              
    <Banner className="Banner-item">
        <img src={props.image} alt="banner"/>
        <BannerItem className="legend-custom">
            <TitleBanner>{props.title}</TitleBanner>
            <DescriptionBanner>{props.description}</DescriptionBanner>
            <BannerLink to={props.url}><i className="far fa-arrow-alt-circle-right"/></BannerLink>
        </BannerItem>
    </Banner>                
  )
};
BannerImg.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default BannerImg;


