import React, { useState, useEffect } from 'react';
import { Navbar as NavStyle, Menu } from './styles';
import MenuItem from './MenuItem';
import LoginItem from './Login';
import SearchButton from './SearchButton';
import { Row, Col } from 'reactstrap';
import api from '../../../services/api';

const Navbar = () => {
  const [menus, setMenus] = useState([]);


  useEffect(() => {

    const data = [];
    api.get('/cms/menus/1').then((res) => {

      res.data.menu_items.forEach(element => {

        data.push({
          title: element.title,
          url: element.url
        })

      })
      setMenus(data);
    })
      .catch((err) => {
        console.error('Erro ao buscar Cursos: ', err);
      })
    return () => {
      setMenus([]);
    };
  }, []);




  return (
    <NavStyle className="nav h-100 ">
      <Row className="h-100 m-0">
        <Col xs={{ size: 12 }} lg="9" className="h-100 p-0 menu-bg">
          <Menu className="d-flex justify-content-lg-between flex-column flex-lg-row nav-menu pr-3 m-0 h-100">
            {menus.map((menu, i) => <MenuItem key={`menu-item-${i}`} title={menu.title} url={menu.url} />)}
            <div className="d-block d-lg-none mt-4">
              <LoginItem />
            </div>
          </Menu>
        </Col>
        <Col xs="1" className="p-0 d-none d-lg-flex align-items-center justify-content-center" >
          <SearchButton />
        </Col>
        <Col xs="2" className="d-none d-lg-block p-0">
          <LoginItem />
        </Col>
      </Row>

    </NavStyle>
  );
};

export default Navbar;
