import React, { useState } from 'react';
import { FetauredImageStyle, CursoStyle } from './styles';
import { Link } from 'react-router-dom';
import InternalTitle from '../../../components/Theme/Title/InternalTitle';
import { Container, Button, Col, Row, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import stripTags from 'striptags';
import format from '../../../utils/format';
import { DataCurso, InfoCurso } from '../../Home/Cursos/Presencial/style';
import api from '../../../services/api';
import userPlaceholder from '../../../assets/images/athie_placeholder.png'

const CursoPagePresencial = ({ course, ...props }) => {
  const [modal, setModal] = useState(false);
  const [alertModal, setAlertModal] = useState('');
  const toggle = () => setModal(!modal);

  function matricula() {
    let date = new Date();
    api.post('/lms/enrollments',
      {
        "status": "Matriculado",
        "user_iri": atob(localStorage.getItem(`user-endpoint`)),
        "classroom_course": window.location.pathname.replace('/presencial', ''),
        "enrolled_at": `${date.getFullYear()}-${date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()}-${date.getDay() < 10 ? '0' + date.getDay() : date.getDay()} ${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}:${date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()}`,
        "active": true
      })
      .then((resp) => {
        setAlertModal(`Você acaba de se matricular nesse curso!`);
        setModal(true);
      })
      .catch((err) => {
        console.error('Erro ao realizar Matricula', err)
      })
  }
  return (
    <CursoStyle>
      <InternalTitle value={course.titulo} />
      {course.data !== undefined &&
        <InfoCurso className="info-curso container p-0">
          <div className="box-dia"><h2 className="mr-2">{format.data(course.data, 'numberDay')}</h2></div>
          <div>
            <DataCurso>{format.data(course.data, 'stringMonth')}</DataCurso>
            <p className="m-0">{format.data(course.data, 'stringDay')} | {format.data(course.data, 'hour').split(':')[0]}h{format.data(course.data, 'hour').split(':')[1]}</p>
          </div>

        </InfoCurso>}
      <Container>
        <Row>
          <Col lg="7" className="pl-0 pr-0 pr-lg-4">
            <FetauredImageStyle>
              <div className="box-image">
                <img src={typeof course.image !== 'undefined' ? course.image.urlPublica : userPlaceholder} alt={course.titulo} />
              </div>
            </FetauredImageStyle>
          </Col>
          <Col lg="5" className="information-box">
            <h3>Informações</h3>
            {stripTags(course.information)}
          </Col>
        </Row>
        <Row className="my-2 my-lg-5 p-0 dadosCurso">
          <Col lg="3" className="pl-0 pr-0 pr-lg-4">
            <h2>Local</h2>
            <span>{course.local}</span>
          </Col>
          <Col lg="3" className="pl-0 pr-0 pr-lg-4">
            <h2>Endereço</h2>
            <span>{course.endereco}</span>
          </Col>
          <Col lg="3" className="pl-0 pr-0 pr-lg-2">
            <h2>Cidade</h2>
            <span>{course.cidade}</span>
          </Col>
          <Col className="my-4 my-lg-0  col 12 col-lg-3 pr-0 d-flex justify-content-center align-items-center">
            <Link to="#" onClick={matricula} className="addCurso">Participar deste Curso</Link>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle} >
          <ModalBody>
            {alertModal}
          </ModalBody>
          <ModalFooter>
            <Button className="buttomModal" onClick={toggle}>OK</Button>
          </ModalFooter>
        </Modal>
      </Container>



    </CursoStyle>

  )
};
export default CursoPagePresencial;
