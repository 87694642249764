import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Internal from './pages/Internal';
import Distancia from './pages/Cursos/Online';
import Presencial from './pages/Cursos/Presencial';
import CursoDistancia from './pages/Cursos/Internas/Online';
import CursoPresencial from './pages/Cursos/Internas/Presencial';
import Live from './pages/Live';
import Faq from './pages/Faq';
import Depoimentos from './pages/Depoimentos';
import Contato from './pages/Contato';
import Sobre from './pages/Sobre';
import MeusCursos from './pages/MeusCursos';
import Trilhas from './pages/Trilhas';
import Login from './pages/Login';
import MeusDados from './pages/MeusDados'; 
import Cadastrar from './pages/Cadastrar';
import TrilhaInterna from './pages/Trilhas/Internas';


const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/404" exact component={Home} />
      <Route path="/" exact component={Home} />
      <Route path="/online/lms/courses" exact component={Distancia} />
      <Route path="/online/lms/courses/:id" exact component={CursoDistancia} />

      <Route path="/presencial/lms/classroom_courses" exact component={Presencial} />
      <Route path="/presencial/lms/classroom_courses/:id" exact component={CursoPresencial} />  

      <Route path="/live" exact component={Live} />

      <Route path="/faq" exact component={Faq} />

      <Route path="/depoimentos" exact component={ Depoimentos } />

      <Route path="/contato" exact component={ Contato } />

      <Route path="/sobre" exact component={ Sobre } />

      <Route path="/meuscursos" exact component={ MeusCursos } />

      <Route path="/trails" exact component={ Trilhas } />
      <Route path="/trails/:id" exact component={ TrilhaInterna } />

      <Route path="/login" exact component={ Login } />
      
      <Route path="/meusdados" exact component={ MeusDados } />

      <Route path="/cadastrar" exact component={ Cadastrar } /> 
      <Route path="/page/:slug" exact component={ Internal } /> 

      
    </Switch>
  </BrowserRouter>
);

export default Routes;
