import styled from 'styled-components';

export const CourseBox = styled.div`
    display: flex;
    flex-direction: column;
    & .trilha{
        height: 30px;
        display: block;
        position: relative;
        &:before {
            content: "";
            width: 20px;
            height: 10px;
            display: block;
            background-color: #a28d7c;
            left: 50%;position: absolute;
            transform: translateX(-50%);
            /* margin:  0 5px; */
            z-index: 2;
            border: 5px solid #ffffff;
            border-bottom: 0;
            border-top: 0;
        }

        &:after {
            content: "";
            top: 5px;
            width: 100%;
            height: 1px;
            display: block;
            background-color: #a28d7c;
            left: 50%;
            position: absolute;
            /* transform: translateY(-50%); */
            z-index: 1;
        }
    }
   
`;
export const FilterStyle = styled.div`
    .searchFilter {
        position: relative;
        display: flex;
        align-items: center;
    }

    .searchFilter i {
        position: absolute;
        right: 15px;
        font-weight: 500;
    }
`;