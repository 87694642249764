import styled from 'styled-components';
import {Link} from 'react-router-dom';

export const CursoItem = styled(Link)`
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  transition: ease all 300ms;
  text-decoration: none;
  cursor: pointer;
  color: #252527;
  &:hover {
    color: #fff;
    text-decoration: none;
    background-color: #252527;
    & h2,
    & p,
    & h4,
    & .fas,
    fas,
    & .far {
      color: #fff;
    }
  }
  &.oneQuater {
    max-width: 25% !important;
  }
`;
export const Cursoimage = styled.div`
  position: relative;
  padding-bottom: 58%;
  overflow: hidden;
  &.atual:before {
    content: 'Em andamento';
    position: absolute;
    width: 100%;
    background-color: #00000087;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &.locked:before {
    content: '\f30d';
    /* content: ''; */
    position: absolute;
    width: 100%;
    background-color: #00000087;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Font Awesome 5 Pro';
    font-size: 1.3em;
  }
  &.unlocked:before {
    content: '';
    /* content: '\f13e'; */
    position: absolute;
    width: 100%;
    /* background-color: #00000087; */
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Font Awesome 5 Pro';
    font-size: 1.3em;
  }
  &.prova-pendente:before,
  &.prova-pendente-atual:before {
    content: 'Prova Pendente';
    position: absolute;
    width: 100%;
    background-color: #00000087;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  &.concluido-locked:before,
  &.concluido-atual:before {
    content: 'Concluído';
    position: absolute;
    width: 100%;
    background-color: #000000db;
    /* padding-bottom: 58%; */
    height: 100%;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
`;
export const InfoCurso = styled.div`
  display: flex;
  padding: 15px 0px;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 1px solid #a28d7c26;
  margin: 0 15px;
  align-items: center;
  @media (max-width: 1200px) {
    font-size: 0.6em;
  }
  @media (max-width: 768px) {
    font-size: 0.8em;
  }
`;
export const TextContent = styled.div`
  position: absolute !important;
  margin: 15px 0px;
`;
export const Stars = styled.div``;
export const Icon = styled.i`
  margin-right: 3px;
`;
export const Duration = styled.p`
  margin: 0 0 0px;
`;
export const Views = styled.p`
  margin: 0 0 0px;
`;
export const DadosCurso = styled.div`
  padding: 15px;
`;
export const Professor = styled.div`
  display: flex;
`;
export const ImgProfessor = styled.div`
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  position: relative;
  margin-right: 5px;
`;
export const UserImg = styled.img`
  position: absolute;
  left: 0;
  object-fit: cover;
  height: 100%;
`;
export const NomeProfessor = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
`;
export const DataCurso = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
`;
export const TitleCurso = styled.h4`
  margin: 0;
  font-size: 13px;
  text-align: left;
  color: #000;
  font-style: italic;
  font-weight: 100;
  margin-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const DescriptionCurso = styled.p`
  font-weight: bold;
  text-align: left;
  font-size: 1.1em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  min-height: 78px;
  & p {
    font-weight: bold;
    text-align: left;
    font-size: 1.1em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    min-height: 78px;
  }
`;
export const CursoLink = styled.span`
  text-align: left;
  color: #a28d7c;
  display: block;
  font-size: 22px;
`;
export const BlockSection = styled.div`
  margin: 40px 0;
  .slider {
    margin: 60px 0 0;
  }
`;
export const TitleCursos = styled.span`
  background-color: #a5907e;
  color: #fff;
  padding: 25px 15px;
  margin: 60px 0;
  @media (max-width: 991px) {
    margin: 0px 0;
  }
`;
export const BoxCursos = styled.div`
  margin: 60px 0;
  background-color: #252527;
  color: #fff;
  padding: 15px;
  & h3 {
    font-size: 2em;
    font-weight: 100;
  }
  & p {
    font-weight: 300;
  }
  & a {
    color: #fff;
    font-size: 25px;
  }
  @media (max-width: 991px) {
    margin: 0px 0;
  }
`;
