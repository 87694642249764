import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';

const Internal = (props) => {
 
  const {slug} = props.match.params;
  console.error(props.match.params,slug);
  return(
    <Base slug={slug} />
  )
}

export default Internal;
