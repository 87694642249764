import React, {useState, useEffect} from 'react';
import Base from '../../../components/Theme/base';
import CourseItem from '../../../components/Home/Cursos/Distancia/CourseItem';
import {CourseBox, TrilhaStyle, ProvaStyle} from './styles';
import CursoPage from '../../../components/Cursos/Online';
import {Container, Col, Row} from 'reactstrap';
import api from '../../../services/api';
import Slider from 'react-slick';
// import getCurrentUser from '../../../utils/getCurrentUser';
import ContextTrail from '../../../components/Cursos/Online/contextTrail';
import Lottie from 'react-lottie';
import congratsData from './congrats.json';
import {mountTrail} from './mountTrail';

const TrilhaInterna = () => {
  const [trilha, setTrilha] = useState({});
  const [reload, setReload] = useState(false);
  const [completedTrail, setCompletedTrail] = useState(true);
  const [currentCourse, setCurrentCourse] = useState(0);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: congratsData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    const data = [];
    api
      .get(`/lms${window.location.pathname}`)
      .then((response) => {
        response.data.trails_courses.forEach((res) => {
          if (res && res.course) {
            data.push(mountTrail(res, response));
          }
        });
        setTrilha(
          Object.assign(trilha, {
            titulo: response.data.title,
            cursos: data,
            force_courses_sequence: response.data.force_courses_sequence,
          }),
        );
        setActive((prevState) => (prevState = !prevState));
      })
      .catch((err) => {
        console.error('Erro ao buscar Cursos: ', err);
      });
    return () => {
      setTrilha([]);
    };
  }, [reload]);

  const [active, setActive] = useState(false);
  const [filter] = useState('');

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  function expand(e) {
    setActive((prevState) => (prevState = !prevState));
  }

  // const checkExistCourseInProgress = (courses, indexCourseChecking) => {
  //   const existCourseInProgress = courses.find((course, index) => {
  //     if (
  //       // /index !== indexCourseChecking &&
  //       course.course_stats &&
  //       course.course_stats.length &&
  //       course.course_stats[0].course_status
  //     ) {
  //       return (
  //         course.course_stats[0].course_status === 'EM_ANDAMENTO' ||
  //         !checkedDidExamCourse(course)
  //       );
  //     }
  //   });

  //   if (existCourseInProgress) {
  //     return existCourseInProgress;
  //   }
  //   return false;
  // };

  // const checkExistCoursePreviousNotDid = (coursesPreviousToCurrent) => {
  //   const existCoursePreviousNotDid = coursesPreviousToCurrent.find(
  //     (course) => {
  //       if (course.course_stats) {
  //         return course.course_stats.length <= 0;
  //       }
  //     },
  //   );

  //   if (existCoursePreviousNotDid) {
  //     return existCoursePreviousNotDid;
  //   }
  //   return false;
  // };

  // const checkedDidExamCourse = (course) => {
  //   const existExam =
  //     course.exams &&
  //     course.exams.length &&
  //     course.exams[0] &&
  //     course.exams[0].exam
  //       ? course.exams[0].exam
  //       : null;

  //   if (existExam && existExam.mandatory_approval) {
  //     return (
  //       course.course_stats &&
  //       course.course_stats.length &&
  //       course.course_stats[0] &&
  //       course.course_stats[0].max_grade >=
  //         course.course_stats[0].approval_percentage
  //     );
  //   } else if (existExam && !existExam.mandatory_approval) {
  //     return (
  //       course.course_stats &&
  //       course.course_stats.length &&
  //       course.course_stats[0] &&
  //       course.course_stats[0].attempts > 0
  //     );
  //   }

  //   return true;
  // };

  // const applyClassNameAndSitutationCourse = (
  //   course,
  //   courseConclued,
  //   courseCanUnlock,
  //   force_courses_sequence,
  // ) => {
  //   let classNameCourse = null;
  //   let situtationCourse = null;

  //   if (course.progressoTotal === 100 && courseConclued) {
  //     classNameCourse = 'concluido atual';
  //     situtationCourse = 'concluido-atual';
  //   } else if (course.progressoTotal === 100 && !courseConclued) {
  //     classNameCourse = 'prova-pendente atual';
  //     situtationCourse = 'prova-pendente-atual';
  //   } else if (course.progressoTotal !== 100 && courseCanUnlock) {
  //     classNameCourse = 'atual';
  //     situtationCourse = 'atual';
  //   } else if (course.progressoTotal !== 100 && !courseCanUnlock) {
  //     if (!force_courses_sequence) {
  //       classNameCourse = 'un-locked';
  //       situtationCourse = 'unlocked';
  //     } else {
  //       classNameCourse = 'locked';
  //       situtationCourse = 'locked';
  //     }
  //   }

  //   return {classNameCourse: classNameCourse, situation: situtationCourse};
  // };

  // const checkCourseIsBlocked = (currentCourse) => {
  //   if (currentCourse) {
  //     const existCourseInProgress = checkExistCourseInProgress(
  //       trilha.cursos,
  //       currentCourse,
  //     );
  //     if (!existCourseInProgress) {
  //       const existCoursePreviousNotDid = checkExistCoursePreviousNotDid(
  //         trilha.cursos.slice(0, currentCourse),
  //       );
  //       return !existCoursePreviousNotDid &&
  //         trilha.cursos[currentCourse] &&
  //         trilha.cursos[currentCourse].course_stats &&
  //         trilha.cursos[currentCourse].course_stats.length > 0
  //         ? currentCourse
  //         : trilha.cursos.findIndex(
  //             (course) => existCoursePreviousNotDid.id === course.id,
  //           );
  //     } else {
  //       return trilha.cursos.findIndex(
  //         (course) => existCourseInProgress.id === course.id,
  //       );
  //     }
  //   }
  //   return currentCourse;
  // };

  // REFACTOR RULES

  let aux = [];
  const extractExam = (itemCourse) => {
    if (itemCourse.exams && itemCourse.exams.length > 0) {
      return itemCourse.exams[0].exam;
    }
    return null;
  };
  const extractStats = (itemCourse) => {
    if (
      itemCourse &&
      itemCourse.course_stats &&
      itemCourse.course_stats.length > 0 &&
      itemCourse.course_stats[0].course_status
    ) {
      return itemCourse.course_stats[0];
    }
    return null;
  };

  const auxStats = () => {
    aux = [];
    trilha.cursos.forEach((item) => {
      let itemStat = extractStats(item)
        ? extractStats(item).course_status
        : null;
      let itemExam = extractExam(item);
      if (itemStat) {
        if (
          itemStat === 'CONCLUIDO' &&
          itemExam &&
          itemExam.mandatory_approval
        ) {
          if (extractStats(item).max_grade >= itemExam.approval_percentage) {
            aux.push(itemStat);
          } else {
            aux.push('PROVA_PENDENTE');
          }
        } else {
          aux.push(itemStat);
        }
      } else {
        aux.push('EM_ANDAMENTO');
      }
    });

    return aux;
  };
  const firstInProgress = (statsArray) => {
    let position = statsArray.findIndex((item) => item === 'EM_ANDAMENTO');
    if (position < 0) {
      position = statsArray.findIndex((item) => item === 'PROVA_PENDENTE');
    }
    return position > 0 ? position : statsArray.length - 1;
  };
  const getSituation = (index) => {
    let statsArray = null;
    let classNameCourse = null;
    let situtationCourse = null;

    if (aux.length > 0) {
      statsArray = aux;
    } else {
      statsArray = auxStats();
    }

    if (statsArray[index] === 'CONCLUIDO') {
      classNameCourse = 'concluido atual';
      situtationCourse = 'concluido-atual';
    } else if (statsArray[index] === 'PROVA_PENDENTE') {
      classNameCourse = 'prova-pendente atual';
      situtationCourse = 'prova-pendente-atual';
    } else if (firstInProgress(statsArray) === index) {
      classNameCourse = 'atual';
      situtationCourse = 'atual';
    } else if (statsArray[index] === 'EM_ANDAMENTO') {
      if (!trilha.force_courses_sequence) {
        classNameCourse = 'un-locked';
        situtationCourse = 'unlocked';
      } else {
        classNameCourse = 'locked';
        situtationCourse = 'locked';
      }
    }

    return {classNameCourse: classNameCourse, situation: situtationCourse};
  };

  useEffect(() => {
    let statsArray = null;
    const course_id = new URLSearchParams(window.location.search).get(
      'course_id',
    );
    if (aux.length > 0) {
      statsArray = aux;
    } else if (trilha.cursos && trilha.cursos.length > 0) {
      statsArray = auxStats();
    }
    if (statsArray && !course_id) {
      setCurrentCourse(firstInProgress(statsArray));
      let testStats = statsArray.every((status) => status === 'CONCLUIDO');

      setCompletedTrail(testStats);
    } else if (course_id) {
      setCurrentCourse(course_id);
    }
  }, [trilha.cursos]);
  // useEffect(() => {
  //   // setCurrentCourse(firstInProgress(auxStats()));
  // }, []);
  return (
    <Base>
      <ContextTrail.Provider value={setReload}>
        <Container className="p-0">
          <TrilhaStyle>
            <Row className="py-3 pl-0 metaTrilha">
              <Col xs="12" lg="3" className="p-lg-0 d-flex align-items-center">
                {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 && (
                  <h4 className="m-0">
                    Trilha {trilha.titulo} | {trilha.cursos.length} cursos
                  </h4>
                )}
              </Col>
              {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 && (
                <Col xs="12" lg="6" className="mt-3 mt-lg-0 timeLine">
                  {trilha.cursos.map((curso, i) => {
                    return active ? (
                      <span
                        key={`trilha-course-${i}`}
                        className={`trilha ${
                          currentCourse === i ? 'curso-atual' : ''
                        } ${
                          auxStats()[i] === 'CONCLUIDO'
                            ? 'completo'
                            : 'incompleto'
                        }`}>
                        <i className="far fa-check-square" />
                      </span>
                    ) : (
                      ''
                    );
                  })}
                </Col>
              )}

              <Col xs="12" lg="3" className="mt-5 mt-lg-0 p-lg-0">
                <button onClick={expand} className="m-0 col-12 LoadMoreButtom">
                  Ver detalhes
                  <i className="fal fa-plus-circle ml-2 font-22" />
                </button>
              </Col>
              {trilha && trilha.cursos && completedTrail && (
                <Col xs={12}>
                  <ProvaStyle className="d-flex  justify-content-center ">
                    <h3 className="col-12 p-lg-0 col-lg-12 d-flex justify-content-center align-items-center text-center ">
                      Parabéns! Você concluiu essa Trilha
                      <Lottie options={defaultOptions} height={50} width={50} />
                    </h3>
                  </ProvaStyle>
                </Col>
              )}
              <Col
                xs="12"
                className={`CourseBlock mt-4 mb-4 pt-5 px-0 ${
                  !active ? '' : 'd-none'
                }`}>
                {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 && (
                  <Slider {...settings}>
                    {trilha.cursos.map((course, j) => {
                      let courseConclued =
                        auxStats()[j] === 'CONCLUIDO' ? true : false;
                      let situationCourse = getSituation(j);

                      return filter === '' ||
                        course.titulo
                          .toLowerCase()
                          .indexOf(filter.toLowerCase()) > -1 ? (
                        <CourseBox key={`courseItem-${j}`}>
                          <div className="timeLine my-2">
                            <span
                              className={`trilha ${
                                currentCourse === j ? 'atual' : ''
                              } ${courseConclued ? 'completo' : 'incompleto'}`}>
                              <i className="far fa-check-square" />
                            </span>
                          </div>
                          <div
                            className={`trail-card ${situationCourse.classNameCourse} p-3 p-md-0`}
                            onClick={() => setCurrentCourse(j)}>
                            <CourseItem
                              situation={`${situationCourse.situation}`}
                              course={course}
                            />
                          </div>
                        </CourseBox>
                      ) : (
                        ''
                      );
                    })}
                  </Slider>
                )}
              </Col>
            </Row>
          </TrilhaStyle>
        </Container>
        {/* <Title value="Trilhas"/> */}
        <Container className="p-0">
          {trilha.hasOwnProperty('cursos') && trilha.cursos.length > 0 && (
            <CursoPage
              course={trilha.cursos[currentCourse]}
              setReload={setReload}
            />
          )}
        </Container>
      </ContextTrail.Provider>
    </Base>
  );
};
export default TrilhaInterna;
