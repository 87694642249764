import { USER } from '../actions/user';

const INITIAL_STATE = {};

export default function user(state = INITIAL_STATE, action) {
  if (action.type === USER) {
    return { user: action.payload };
  }
  return state;
}
