import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../services/api';
import ContextTrail from '../Cursos/Online/contextTrail';

const FilePDF = ({ url, refreshProgress, id, lesson_content_iri }) => {
    const setReload = useContext(ContextTrail);
    const [watchedId] = useState(lesson_content_iri);

    useEffect(() => {
        const updateWatched = async () => {

            if (watchedId === null) {
                await api.post(`/general/watcheds`, {
                    user_iri: atob(localStorage.getItem(`user-endpoint`)),
                    progress: 0,
                    content_iri: `/lms/lesson_contents/${id}`
                }).then((resp) => {
                    console.info('Visualização do arquivo iniciada', resp);
                });
            }
            await api.post(`/general/watcheds`, {
                user_iri: atob(localStorage.getItem(`user-endpoint`)),
                progress: 100,
                content_iri: `/lms/lesson_contents/${id}`,
                watched: true
            })
                .then(() => {
                    if (setReload) {
                        setReload(prev => !prev);
                    }
                    refreshProgress(id);
                });
        }

        updateWatched();
    }, []);

    return (
        <iframe id="featuredPDFView" src={url} />
    )
};

FilePDF.propTypes = {
    url: PropTypes.string.isRequired
}
export default FilePDF;