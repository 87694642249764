export const USER = 'USER';

export const getUser = () => async (dispatch) => {
  const user = localStorage.getItem('current-user');

  return dispatch({
    type: USER,
    payload: user,
  });
};
