import React, { useState, useEffect } from 'react';
import { Header as HeaderStyle, Logo as LogoStyle, MenuStyle, MenuMobileStyle, OverleyCompoment, LinkLogo } from './styles';
import { Container, Row, Col } from 'reactstrap';
import Logo from '../../../assets/images/logo.jpg';
import UtilElement from '../../../utils/utilClass'

import Navbar from '../Navbar'
import SearchButton from '../Navbar/SearchButton'

const Header = () => {
	const [active, setActive] = useState('active');
	function openMenu() {
		setActive(prevState => !prevState);
		if (UtilElement.hasClass('body', 'openMenu')) {
			UtilElement.removeClass('body', 'openMenu');
		} else {
			UtilElement.addClass('body', 'openMenu');
		};
	}

	useEffect(() => {
		return () => {
			if (UtilElement.hasClass('body', 'openMenu')) {
				UtilElement.removeClass('body', 'openMenu');
			}
		}
	}, []);

	return (
		<>
			<OverleyCompoment onClick={() => openMenu()} className={`${active ? '' : 'active'}`} />
			<HeaderStyle className={`box-header`}>
				<Container className="p-3 p-sm-0">
					<Row className="mx-0 d-flex justify-content-between justify-content-lg-start">

						<Col xs="2" md="1" className="pl-0 pr-0 nav-color">
							<LinkLogo to="/">
								<LogoStyle src={Logo} className="img-fluid h-100" />
							</LinkLogo>
						</Col>

						<MenuStyle className="col-11 p-0 nav-height ">
							<div className="d-block d-lg-none mb-2">
								<MenuMobileStyle onClick={openMenu} className="justify-content-end text-end d-flex">
									<i className="fas fa-times mr-2 mt-2" />
								</MenuMobileStyle>
							</div>
							<Navbar />
						</MenuStyle>

						<Col xs="8" sm="9" className="p-0 nav-color d-block d-lg-none">
							<SearchButton />
						</Col>

						<Col xs="2" sm="1" className="p-0 nav-color d-block d-lg-none">
							<MenuMobileStyle onClick={openMenu} ><i className="far fa-bars" /></MenuMobileStyle>
						</Col>
					</Row>
				</Container>
			</HeaderStyle>
		</>
	);
};

export default Header;