import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import InternalTitle from '../../components/Theme/Title/InternalTitle';
import ProgressBar from '../../components/Theme/ProgressBar';
import {Cursos, RespCursos} from './styles';
import {Container, Row, Col} from 'reactstrap';
import CategoriasCursos from './categorias';
import CourseItem from '../../components/Home/Cursos/Distancia/CourseItem';
import api from '../../services/api';
import userPlaceholder from './../../assets/images/athie_placeholder.png';
import CourseRefreshContext from './coursesContext';
import getCurrentUser from '../../utils/getCurrentUser';
import axios from 'axios';
import {getToken} from '../../services/auth';

const MeusCursos = () => {
  const [categoriesCourses, setCategoriesCourses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [titleCategory, setTitleCategory] = useState('');

  const prepareNumber = (number) => {
    return (number < 10 && number !== '' ? '0' + number : number) || '00';
  };

  function setListCourses(title) {
    switch (title) {
      case 'Cursos Assistidos':
        getListCourses(title, '/lms/course_stats?course_status=CONCLUIDO');
        break;
      case 'Cursos Favoritos':
        getListCourses(
          title,
          '/general/evaluations?match[content_iri]=/lms/courses&type=FAVORITE',
        );
        break;
      case 'Cursos Em Andamento':
        getListCourses(title, '/lms/course_stats?course_status=EM_ANDAMENTO');
        break;
      case 'Cursos Avaliados':
        getListCourses(
          title,
          '/general/evaluations?match[content_iri]=/lms/courses&type=RATING',
        );
        break;
      case 'Videos Assistidos':
        getListCourses(
          title,
          '/general/watcheds?match[content_iri]=/lms/lesson_contents&watched=true',
        );
        break;
      case 'Meus Certificados':
        getListCourses(title, '/lms/course_stats?course_status=CONCLUIDO');
        break;
      default:
        break;
    }
    setTitleCategory(title);
  }
  function getIcon(title) {
    switch (title) {
      case 'Cursos Favoritos':
        return <i className="fas fa-heart"></i>;
        break;
      case 'Cursos Avaliados':
        return <i className="fas fa-star"></i>;
        break;
      case 'Meus Certificados':
        return (
          <button className=" m-0 btn-cert LoadMoreButtom">
            Baixar Certificado
          </button>
        );
        break;
      default:
        return '';
    }
  }
  function getCertificado(url, course_id) {
    if (url === '#') {
      axios({
        url: `https://${process.env.REACT_APP_API_URL}/lms/courses/${course_id}/certificate_issuance`,
        method: 'GET',
        responseType: 'arraybuffer', // important
        headers: {Authorization: getToken()},
      }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {type: 'application/pdf'}),
        );
        window.open(url, '_blank');
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('title', 'file.pdf');
        // link.setAttribute('target', '_blank');
        // link.setAttribute('rel', 'noopener noreferrer');
        // document.body.appendChild(link);
        // link.click();
      });
    }
  }

  function getListCourses(title, endpoint) {
    const data = [];
    api
      .get(endpoint)
      .then((res) => {
        if (res.data.length > 0) {
          res.data.forEach((el) => {
            if (el !== null) {
              let element = el.course;
              if (title === 'Videos Assistidos' && el.content !== undefined) {
                element = el.content.lesson.course;
              } else if (
                title === 'Cursos Favoritos' ||
                (title === 'Cursos Avaliados' && el.content !== undefined)
              ) {
                element = el.content;
              }

              if (
                title === 'Meus Certificados' &&
                !!el.course.course_exams.length &&
                !!el.course.course_exams[0].exam.mandatory_approval
              ) {
                if (el.max_grade < el.approval_percentage) {
                  element = undefined;
                }
              }

              if (element !== undefined) {
                if (el.hasOwnProperty('progress')) {
                  element.progress = el.progress;
                } else if (
                  element.hasOwnProperty('course_stats') &&
                  element.course_stats.filter(
                    (stat) => stat.user_iri === getCurrentUser(),
                  ).length > 0
                ) {
                  element.progress = element.course_stats.filter(
                    (stat) => stat.user_iri === getCurrentUser(),
                  )[0].progress;
                } else {
                  element.progress = 0;
                }

                data.push({
                  _id: `/lms/courses/${element.id}`,
                  id: element.id,
                  image: {
                    urlPublica:
                      element.image !== null
                        ? element.image.urlPublica
                        : userPlaceholder,
                  },
                  nota: 5,
                  category_complement: getIcon(title),
                  duration: element.duration,
                  data: element.created_at,
                  views: element.views,
                  photo:
                    el.user.profile.hasOwnProperty('avatar') &&
                    el.user.profile.avatar.file != null
                      ? el.user.profile.avatar.file.urlPublica
                      : userPlaceholder,
                  autor: el.user.name,
                  titulo: element.title,
                  progresso: element.progress,
                  description: element.summary,
                  url:
                    title === 'Meus Certificados'
                      ? '#'
                      : `/online/lms/courses/${element.id}`,
                });
              }
            }
          });
          setCourses(data);
          return data;
        } else {
          setCourses([]);
          return [];
        }
      })
      .catch((err) => {
        setCourses([]);
        console.error('Erro ao buscar Cursos: ', err);
        return [];
      });
  }

  function getCountCategory(title, endpoint) {
    let data = categoriesCourses;

    if (title == 'Meus Certificados') {
      let temp = [];
      api
        .get(`/lms/course_stats?course_status=CONCLUIDO`)
        .then((response) => {
          const res = response.data;

          if (res.length) {
            res.forEach((item) => {
              if (
                !!item.course.course_exams.length &&
                !!item.course.course_exams[0].exam.mandatory_approval
              ) {
                if (item.max_grade >= item.approval_percentage) {
                  temp.push(item.course.id);
                }
              } else {
                temp.push(item.course.id);
              }
            });
          }
          data.push({
            title: title,
            qtd: prepareNumber(temp.length),
          });
          getListCourses(title);
          setCategoriesCourses(data);
        })
        .catch((err) => {
          console.error('Erro ao buscar meus Cursos' + err);
        });

      // setCertificates({
      //   title: 'Meus Certificados',
      //   qtd: prepareNumber(temp.length),
      // });
    } else {
      api
        .get(endpoint)
        .then((res) => {
          data.push({
            title: title,
            qtd: prepareNumber(res.data),
          });

          getListCourses(title);
        })
        .catch((err) => {
          console.error('Erro ao buscar quantidade de cursos avaliados' + err);
        });
      setCategoriesCourses(data);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        (async () => {
          // MEUS CERTIFICADOS
          await getCountCategory(
            'Meus Certificados',
            `/lms/course_stats/count_by?course_status=CONCLUIDO`,
          );
          getCountCategory(
            'Cursos Assistidos',
            `/lms/course_stats/count_by?course_status=CONCLUIDO`,
          );

          // CURSOS FAVORITADOS
          getCountCategory(
            'Cursos Favoritos',
            `/general/evaluations/count_by?match[content_iri]=/lms/courses&type=FAVORITE`,
          );

          // CURSOS Em ANDAMENTO
          getCountCategory(
            'Cursos Em Andamento',
            `/lms/course_stats/count_by?course_status=EM_ANDAMENTO`,
          );

          // CURSOS AVALIADOS
          getCountCategory(
            'Cursos Avaliados',
            `/general/evaluations/count_by?match[content_iri]=/lms/courses&type=RATING`,
          );

          // VIDEOS ASSISTIDOS
          getCountCategory(
            'Videos Assistidos',
            `/general/watcheds/count_by?match[content_iri]=/lms/lesson_contents&watched=true`,
          );
        })();

        // setApprovedExams(temp.filter(onlyUnique));
      } catch (error) {
        console.error('Erro ao buscar Provas realizadas: ', error);
      }
    })();
  }, []);

  return (
    <Base>
      {categoriesCourses && !!categoriesCourses.length && (
        <>
          <Title value="Meus Cursos" />
          <Container className="p-0 mb-5 pb-5">
            <CourseRefreshContext.Provider value={setListCourses}>
              <Row>
                {categoriesCourses.map((categoria, i) => (
                  <CategoriasCursos key={`category-${i}`} list={categoria} />
                ))}
              </Row>
            </CourseRefreshContext.Provider>
            {courses.length > 0 && <InternalTitle value={titleCategory} />}
            <Row>
              <RespCursos className="p-0 col-12">
                {courses.length > 0 && (
                  <Cursos className="d-flex flex-wrap">
                    {courses.map((course, i) => (
                      <Col
                        onClick={(e) => getCertificado(course.url, course.id)}
                        md="6"
                        lg="3"
                        key={`courseItem-${i}`}
                        className="p-0 mb-5 d-flex flex-column">
                        <CourseItem
                          categoryCourse={course.category_complement}
                          course={course}
                        />
                        <div className="pl-4 pr-2 d-flex ">
                          <b className="mr-2">
                            {parseInt(course.progresso.toFixed(2))}%
                          </b>{' '}
                          <ProgressBar progress={course.progresso} />
                        </div>
                      </Col>
                    ))}
                  </Cursos>
                )}
              </RespCursos>
            </Row>
          </Container>
        </>
      )}
    </Base>
  );
};
export default MeusCursos;
