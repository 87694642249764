import React, {useState, useEffect} from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { BannerImg, ContentPage } from './styles';
import Banner from '../../assets/images/sobre.png';
import Banner2 from '../../assets/images/banner2.png';
import {  Container, Col, Row } from 'reactstrap';
import api from '../../services/api';
import ContainerLoading from '../../components/Theme/Loading';


const SobrePage = () =>{
  const [pageContent, setPageContent] = useState();
  const [loading, setLoading] = useState(false); 
  useEffect(() => {
    api.get('/cms/pages?slug=sobre')
      .then(pageData => {
        setPageContent(pageData.data[0]);
        // setLoading(true);
      })
      .catch((err) =>{
        console.error('Erro ao buscar Paginas...', err);
      });

      // return () => {
      //   setPageContent([]);
      // };

  },[]);
  
  return(
    
    <Base>
      <Title value={typeof pageContent !== 'undefined' ? pageContent.title : ''} />       
      <Container className="p-0 mb-5 pb-5">
        <ContentPage>
          <BannerImg>
            {typeof pageContent !== 'undefined' &&
              <img src={pageContent.image.urlPublica} alt="Sobre Banner" />}
          </BannerImg>
          <Row className="content">
            <Col lg="3">
              <h2>{typeof pageContent !== 'undefined' && pageContent.pages_blocks.length > 0 ? pageContent.pages_blocks[0].block.title:''}</h2>
            </Col>
            <Col lg="8"> 
            {typeof pageContent !== 'undefined' && pageContent.content.length > 0 &&
              <div className="content-text" dangerouslySetInnerHTML={{ __html: pageContent.content}}/>}
            </Col>
          </Row>
        </ContentPage>
      </Container>  
    </Base>
  )
    
};
export default SobrePage;
