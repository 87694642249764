import styled from 'styled-components';

export const CourseBox = styled.div`
  display: flex;
  flex-direction: column;

  & .trail-card {
    pointer-events: none;
    &.concluido,
    &.atual,
    &.un-locked {
      pointer-events: auto;
    }
  }

  & .trilha {
    height: 30px;
    display: block;
    position: relative;
    &:before {
      content: '';
      width: 20px;
      height: 10px;
      display: block;
      background-color: #a28d7c;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      /* margin:  0 5px; */
      z-index: 2;
      border: 5px solid #ffffff;
      border-bottom: 0;
      border-top: 0;
    }

    &:after {
      content: '';
      top: 5px;
      width: 100%;
      height: 1px;
      display: block;
      background-color: #a28d7c;
      left: 50%;
      position: absolute;
      /* transform: translateY(-50%); */
      z-index: 1;
    }
  }
  .timeLine {
    .trilha {
      justify-content: center;
      align-content: center;
      &:after {
        top: unset;
      }
      &.completo {
        &:after {
          background-color: #252527;
        }
      }
      &.atual i {
        font-size: 1.2em;
        background-color: #252527;
        color: #252527;
      }
    }
  }
`;

export const ProvaStyle = styled.div`
  background-color: #1cc521;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px;
  margin: 40px 0 100px;
  & div[aria-label='animation'] {
    margin: 0 !important;
  }
  & h3 {
    color: #fff;
    margin: 0 0px 0 0;
  }
  & a {
    background-color: #a28d7c;
    padding: 10px 60px;
    font-weight: bold;
    color: #fff;
    &:hover {
      color: #a28d7c;
      text-decoration: none;
      background-color: #fff;
    }
  }
`;

export const TrilhaStyle = styled.div`
  .metaTrilha {
    border-bottom: 1px solid;
    border-top: 1px solid;
    margin-top: 80px;
  }
  .timeLine {
    display: flex;
    justify-content: space-between;
    & .trilha:before {
      content: '';
      width: 100%;
      position: absolute;
      background-color: #a58a77;
      display: block;
      height: 1px;
    }
    & .trilha {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      &.completo {
        &:before {
          background-color: #252527;
        }
        & i {
          background-color: #fff;
          font-size: 0.9em;
          color: #252527;
        }
      }
      &.curso-atual i {
        font-size: 1.2em;
        background-color: #252527;
        color: #252527;
      }
      i {
        border: 4px solid #fff;
        z-index: 99;
        background-color: #a58a77;
        color: #a58a77;
        font-size: 0.8em;
      }
      &:last-child:before {
        display: none;
      }
    }
  }

  /* .timeLine 

    .timeLine 

    .timeLine .trilha 

    .timeLine .trilha */
`;
