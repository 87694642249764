import React, {useState, useContext} from 'react';
import Script from 'react-load-script';
import api from '../../services/api';
import ContextTrail from '../Cursos/Online/contextTrail';

const SambaVideo = ({reference, lesson_content_iri, id, ...props}) => {
  const playerKey = '9f26b96ab4e54638274b1f118b5a35bf';
  const [load, setLoad] = useState(false);
  const [watchedId] = useState(lesson_content_iri);
  const progress = true;
  const player = `player-${reference}`;
  let sambaPlayer = false;
  let setProgress = false;
  const setReload = useContext(ContextTrail);

  if (load) {
    const eventListener = (player) => {
      switch (player.event) {
        case 'onLoad':
          break;
        case 'onStart':
          if (watchedId === null) {
            api.post(`/general/watcheds`, {
              user_iri: atob(localStorage.getItem(`user-endpoint`)),
              progress: 0,
              content_iri: `/lms/lesson_contents/${id}`,
            });
          }
          break;
        case 'onListen':
          if (
            !setProgress &&
            player.eventParam > 1 &&
            parseInt(player.eventParam) % 15 === 0
          ) {
            setProgress = true;
            api.post(`/general/watcheds`, {
              user_iri: atob(localStorage.getItem(`user-endpoint`)),
              progress: (player.eventParam * 100) / player.duration,
              content_iri: `/lms/lesson_contents/${id}`,
            });
            setTimeout(() => {
              setProgress = false;
            }, 1000);
          }
          break;
        case 'onPause':
          break;
        case 'onResume':
          break;
        case 'onSeek':
          break;
        case 'onCuepoint':
          break;
        case 'onMediaView':
          break;
        case 'onProgress':
          break;
        case 'onFinish':
          api
            .post(`/general/watcheds`, {
              user_iri: atob(localStorage.getItem(`user-endpoint`)),
              progress: 100,
              content_iri: `/lms/lesson_contents/${id}`,
              watched: true,
            })
            .then((resp) => {
              if (setReload) {
                setReload((prev) => !prev);
              }
              props.refreshProgress(id);
            });
          break;
        case 'onError':
          break;
        default:
          break;
      }
    };

    if (!sambaPlayer) {
      sambaPlayer = new window.SambaPlayer(player, {
        ph: playerKey,
        m: reference,
        playerParams: {
          enableShare: false,
          resume: progress,
        },
        events: {
          '*': eventListener,
        },
      });
    }
  }

  return (
    <>
      <Script
        url="https://player.sambatech.com.br/v3/samba.player.api.js"
        onLoad={function () {
          setLoad(true);
        }}
      />

      <div className="embed-responsive embed-responsive-16by9 ">
        <div className="embed-responsive-item" id={`player-${reference}`} />
      </div>
    </>
  );
};

export default SambaVideo;
