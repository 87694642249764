import React, { useState } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import { FormStyle } from './styles';
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';
import api from '../../services/api';
import InputMask from 'react-input-mask';

const Faq = () => {
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [subject,setSubject] = useState('');
    const [msg,setMsg] = useState('');

    const [modal, setModal] = useState(false);
    const [emptyInput,setEmptyInput] =  useState(`Todos os campos devem ser preenchidos`)
    const [alertModal,setAlertModal] = useState('');
    const toggle = () => setModal(!modal);

    function sendMenssage(event){
        event.preventDefault();
        if(name === '' ||  phone === '' ||  email === '' ||  subject === '' ||  msg === '' ){
            setAlertModal('Todos os campos devem ser preenchidos');
            setModal(!modal);
        }else{
        api.post('/cms/contact_messages',
            {
                "content": `<p>${msg}</p> <p>&nbsp;</p>\r\n<p> Nome: <b>${name}</b>,</p>\r\n<p> Phone: <b>${phone}</b>,</p> \r\n<p> E-mail: <b>${email}</b></p>` ,
                "subject": subject,
                "user_iri": atob(localStorage.getItem(`user-endpoint`)),
                "ip": "0.0.0.0",
                "user_agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.79 Safari/537.36"
            }).then(function(response){
                setAlertModal('Mensagem envidada. Obrigado pelo contato!');
                setModal(!modal);
                window.location.href = '/';
             })
             .catch((err)=>{
                console.error('Erro ao enviar mensagem: ', err);
                setAlertModal('Erro ao enviar mensagem. Desculpe o transtorno!');
                setModal(!modal);
             })
        }
    }
    function change(event){
        setPhone(event.target.value);
    }


    return (
        <Base>
            <Title value="Contato"/>
            <Container className="p-0 mb-5 pb-5">
                
                 <FormStyle onSubmit={sendMenssage} className="d-flex flex-column">
                    <form action="/cms/contact_messages" method="post">
                        <Row className="mb-3">
                            <Col xs="12" md="12" lg="6">
                                <div className="has-float-label">
                                    <input onKeyUp={event => setName(event.target.value)}  id="input_name" name="input_name" type="text" className="w-100" placeholder="Nome"/>
                                    <label htmlFor="input_name">Digite aqui seu <b>Nome</b></label>                            
                                </div>                            
                            </Col>
                            <Col xs="12" md="12" lg="6">
                                <div className="has-float-label">
                                    <input onKeyUp={event => setEmail(event.target.value)} id="input_email" type="email" className="w-100" placeholder="Email"/>
                                    <label htmlFor="input_email">Digite aqui seu <b>Email</b></label>                            
                                </div>  
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs="12" md="12" lg="6">
                            <div className="has-float-label">
                                    <InputMask mask="(99)9 9999-9999" maskChar={null} value={phone} onChange={change} onKeyUp={event => setPhone(event.target.value)}  id="input_tel" type="tel" className="w-100" placeholder="Telefone"/>
                                    <label htmlFor="input_tel">Digite aqui seu <b>Telefone</b></label>                            
                                </div> 
                            </Col>
                            <Col xs="12" md="12" lg="6" className="mb-5 pb-2">
                                <div className="has-float-label">
                                    <input onKeyUp={event => setSubject(event.target.value)} id="input_assunto" type="text" className="w-100" placeholder="Assunto"/>
                                    <label htmlFor="input_assunto">Digite aqui seu <b>Assunto</b></label>                            
                                </div> 
                            </Col>
                            <Col xs="12" md="12" lg="12" className="mb-2"> 
                                <div className="has-float-label">
                                    <textarea  onKeyUp={event => setMsg(event.target.value)}  id="input_msg" type="text" rows="10" className="w-100" placeholder="Mensagem"/>
                            </div> 
                            </Col>
                        </Row>
                        <Row>
                            <Col cxs="12" className="d-flex justify-content-end">
                            <button className="col-12 col-lg-3 LoadMoreButtom">Enviar Mensagem</button> 
                            </Col>
                        </Row>
                     </form>


                 </FormStyle>
                 <Modal isOpen={modal} toggle={toggle} >
                    <ModalBody>
                        {alertModal}
                    </ModalBody>
                    <ModalFooter>
                    <Button className="buttomModal" onClick={toggle}>OK</Button>
                    </ModalFooter>
                </Modal>
                
            </Container>
        </Base>
    )

};
export default Faq;
