import React, { useContext} from 'react';
import {ContentQuetion} from './styles';
import HandleRefreshQuestions from './handleRefreshQuestion';


const QuestionOption = ({opt,...props}) => {
  const { setAnswers } = useContext(HandleRefreshQuestions);

  return(
    <ContentQuetion className="question">
        <h3  dangerouslySetInnerHTML={{__html:opt.title}} />
        {opt.exam_question_alternatives.length > 0 &&
          <div className="content-questions">
              {opt.exam_question_alternatives.map((alt,i)=> 
              <div key={`question-${i}`} className="inputGroup" onClick={e => setAnswers(opt.id,alt.id)} >
                <input id={`opt-${opt.id}-${i}`} name={`option-${opt.id}`} type="radio"/>
                <label htmlFor={`opt-${opt.id}-${i}`} dangerouslySetInnerHTML={{__html:alt.title}} />
              </div>
           
              )}
          </div>
        }
    </ContentQuetion>
  )
    
};
export default QuestionOption;
