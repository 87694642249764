import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../services/api';
import stripTags from 'striptags';
import format from '../../../../utils/format';
import ContainerLoading from '../../../Theme/Loading';

import {
    CursoItem,
    Cursoimage,
    InfoCurso,
    Stars,
    Duration,
    Views,
    DadosCurso,
    Professor,
    ImgProfessor,
    NomeProfessor,
    DataCurso,
    TitleCurso,
    DescriptionCurso,
    CursoLink,
    UserImg,
    Icon,
} from './style';

const CourseItem = ({ course, ...props }) => {
    const [evaluation, setEvaluation] = useState(course.nota);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const res = course.evaluations || [];
        const ratings =
            res.filter((el) => el.type === 'RATING').length > 0
                ? res.filter((el) => el.type === 'RATING')[0].average
                : 5;

        const favorites =
            res.filter((el) => el.type === 'FAVORITE').length > 0
                ? res.filter((el) => el.type === 'FAVORITE')[0].count
                : 0;

        const likes =
            res.filter((el) => el.type === 'LIKE').length > 0
                ? res.filter((el) => el.type === 'LIKE')[0].count
                : 0;

        setEvaluation({ ratings });
        setLoading(true);
    }, []);

    const stars = [];
    for (var i = 0; i < 5; i++) {
        evaluation.ratings > i ? stars.push('fas') : stars.push('far');
    }

    return loading === false ? (
        <ContainerLoading />
    ) : (
            <CursoItem to={course.url} className={`ml-0 mx-sm-2 ml-lg-4 mr-0`}>
                <Cursoimage className={props.situation}>
                    {course.image.hasOwnProperty('urlPublica') === true && (
                        <UserImg src={course.image.urlPublica} alt="{props.titulo}" />
                    )}
                </Cursoimage>
                <InfoCurso>
                    <Stars>
                        {stars.map((star, i) => (
                            <i key={`star-${i}`} className={`${star} fa-star`} />
                        ))}
                        <span className="font-italic"> {evaluation.ratings}/5</span>
                    </Stars>
                    <Duration>
                        <Icon className="far fa-clock" />
                        {course.duration}
                    </Duration>
                    <Views>
                        <Icon className="fas fa-eye" />
                        {course.views}
                    </Views>
                </InfoCurso>
                <DadosCurso>
                    <Professor>
                        <ImgProfessor>
                            <UserImg src={course.photo} />
                        </ImgProfessor>
                        <span className="d-flex- flex-column">
                            <NomeProfessor>Por:{course.autor}</NomeProfessor>
                            <DataCurso>
                                {format.data(course.data, 'numberDayDate')}{' '}
                                {format
                                    .data(course.data, 'stringMonth')
                                    .slice(0, 3)
                                    .toUpperCase()}{' '}
                                {format.data(course.data, 'stringMouth')}{' '}
                                {format.data(course.data, 'numberYear')}
                            </DataCurso>
                        </span>
                    </Professor>
                    <TitleCurso>{course.titulo}</TitleCurso>
                    <DescriptionCurso
                        dangerouslySetInnerHTML={{ __html: course.description }}
                    />
                    <CursoLink
                        className={
                            props.categoryCourse !== ''
                                ? 'd-flex justify-content-between align-items-center'
                                : ''
                        }>
                        <i className="far fa-arrow-alt-circle-right"></i>
                        {props.categoryCourse !== '' && props.categoryCourse}
                    </CursoLink>
                </DadosCurso>
            </CursoItem>
        );
};

CourseItem.propTypes = {
    course: PropTypes.object.isRequired,
    situation: PropTypes.string,
};

export default CourseItem;
