import React,{ useState, useEffect } from 'react';
import Base from '../../components/Theme/base';
import Title from '../../components/Theme/Title';
import {ContentFaq} from './styles';
import Question from '../../components/Faq/Question';
import {Container, Row} from 'reactstrap';
import api from '../../services/api'

const Faq = () => {
    const [questions, setQuestions] = useState([]);
    const data = [];
    useEffect(()=>{
        api.get('/lms/faqs')
            .then((response)=>{
                response.data.forEach((item)=> {
                    data.push({
                        titulo: item.question,
                        answer: item.answer
                    })
                    
                })
                setQuestions(data);
            })
            .catch((err) => {
                console.error('Erro ao buscar perguntas', err)
            })
        return () => {
            setQuestions([]);
        }; 
    },[])
    
    const [qtd,setQtd] = useState(3);
    function expand(e){    
      setQtd(prevState => prevState = prevState + 3);
    }
    return (
        <Base>
            <Title value="FAQ"/>
            <Container className="p-0 mb-5 pb-5">
                <Row>
                  <ContentFaq  className="col-12 p-4 d-flex flex-column justify-content-center align-items-center">
                      {questions.slice(0,qtd).map((question, i) => 
                          <Question key={`questionItem${i}`} index={i} faqObject={question} />
                      )}
                  </ContentFaq>
                </Row>
                {questions.length > 0 && questions.length > qtd ? 
                  <Row className="p-4 p-lg-0">
                    <button onClick={expand} className="col-12 col-lg-3 LoadMoreButtom">Carregar Mais</button> 
                  </Row>:''}
            </Container>
        </Base>
    )

};
export default Faq;
