import React, {useState,useEffect} from 'react';
import Question from '../../Faq/Question';
import bg from '../../../assets/images/faq-bg.jpg';
import { Row } from 'reactstrap';
import { BlockSection, TitleFaq, ContentFaq , BoxImageStyle, Box,ImageBg} from './style';
import api from '../../../services/api'
import { Link } from 'react-router-dom';

const BlockFaq = (props) => {
  const [questions, setQuestions] = useState([]);
  const data = [];
    useEffect(()=>{
       
       props.content.forEach((item)=> {
            data.push({
                titulo: item.question,
                answer: item.answer
            })
            
        })
        setQuestions(data);
            
        return () => {
            setQuestions([]);
        }; 
    },[])

  return (
    <BlockSection className="container FaqBlock">
      <Row className="align-items-center">
        <BoxImageStyle className=" col-12 col-lg-5 p-0 d-flex flex-column align-items-start">
          <TitleFaq>{props.block.title ? props.block.title: "FAQ"}</TitleFaq>
          <Box>
             <ImageBg src={bg} alt="FAQ"/>
          </Box>           
        </BoxImageStyle>
        <ContentFaq  className="col-12 col-lg-7 p-4 d-flex flex-column">
          <div className="col-12 p-0 faq-box">
             {questions.slice(0, 8).map((question, i) => 
                <Question key={`questionItem${i}`} index={i} faqObject={question} />
             )}
             </div>
             <Link to='/faq' className=""><i className="far fa-arrow-alt-circle-right"/><span> Mais</span></Link>
        </ContentFaq>
        
      </Row>
    </BlockSection>  
  );
};


export default BlockFaq;
